﻿import m from 'mithril'
import { map } from 'lodash'

import Round from '../models/Round'
// Components
import icon from '../components/icon'
import loading from '../components/loading'

import variables from '../helpers/variables'

var navigation = {}
navigation.oninit = (vnode) => {
	if (!Round.list()) Round.getList()
}
navigation.view = (vnode) => {
	return [
		m(
			'nav.collapse.sb-links',
			vnode.attrs.user() && vnode.attrs.user().id && vnode.attrs.user().isMember
				? [
						m('.sb-toc-item.mt-3', [
							m(
								'a.sb-toc-link',
								{
									href: `/`,
									oncreate: m.route.link,
								},
								[m(icon, { iconName: 'dashboard sb-icon' }), 'Dashboard']
							),
						]),
						// m('.sb-toc-item.mt-3', [
						// 	m(
						// 		'a.sb-toc-link',
						// 		{
						// 			href: `/membership`,
						// 			oncreate: m.route.link,
						// 		},
						// 		[m(icon, { iconName: 'photo sb-icon' }), 'Membership']
						// 	),
						// ]),
						m('.sb-toc-item', [
							m(
								'a.sb-toc-link',
								{
									href: `/screenings`,
									oncreate: m.route.link,
								},
								[m(icon, { iconName: 'video-camera sb-icon' }), 'Screenings']
							),
						]),
						m('.sb-toc-header', `${variables.year} Rounds`),
						m('.sb-toc-item', [
							Round.list() &&
								map(Round.list(), (round) => {
									return [
										m(
											'a.sb-toc-link',
											{
												href: round.isViewingRound ? `/viewing/all` : `/rounds/${round.number}`,
												oncreate: m.route.link,
												'data-id': round.id,
												className: Round.current() && Round.current().id === round.id ? 'active' : '',
											},
											[m(icon, { iconName: 'tasks sb-icon' }), round.name]
										),
									]
								}),
						]),
				  ]
				: vnode.attrs.user() && vnode.attrs.user().id && !vnode.attrs.user().isMember
				? [
						m('.sb-toc-item.mt-3', [
							m(
								'a.sb-toc-link',
								{
									href: `/`,
									oncreate: m.route.link,
								},
								[m(icon, { iconName: 'dashboard sb-icon' }), 'Dashboard']
							),
						]),
				  ]
				: m(loading)
		),
	]
}

export default navigation
