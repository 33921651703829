﻿// Libraries
import m from 'mithril'
import DateFormat from 'dateformat'
import { map, filter } from 'lodash'

// Helpers
import getResourceObject from '../helpers/getResourceObject'

// Components
import input from './input'
import icon from './icon'

var formGroup = {}
formGroup.oninit = vnode => {
	vnode.state.id = vnode.attrs.id || `i_${vnode.attrs.objectPath || ''}_${vnode.attrs.property}`
	vnode.state.label = (vnode.attrs.label || vnode.attrs.property) + (vnode.attrs.required ? ' *' : '')
	vnode.state.value =
		vnode.attrs.value ||
		(vnode.attrs.dateformat
			? DateFormat(Date.parse(getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath)[vnode.attrs.property]), 'dd/mm/yyyy HH:MM:ss')
			: getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath)[vnode.attrs.property])
}
formGroup.onbeforeupdate = vnode => {
	vnode.state.value =
		vnode.attrs.value ||
		(vnode.attrs.dateformat
			? DateFormat(Date.parse(getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath)[vnode.attrs.property]), 'dd/mm/yyyy HH:MM:ss')
			: getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath)[vnode.attrs.property])
}
formGroup.view = vnode => {
	return m(
		'.form-group.form-group-flex',
		{
			className: filter(
				[
					vnode.attrs.className,
					vnode.attrs.type === 'checkbox' ? 'custom-control custom-checkbox' : null,
					vnode.attrs.required ? 'form-group-required' : null,
					vnode.attrs.isStatic ? 'form-group-static' : null,
					vnode.attrs.isReadOnly ? 'form-group-readonly' : null,
				],
				val => val
			).join(' '),
		},
		[
			vnode.attrs.type === 'checkbox' ? m(input, vnode.attrs) : vnode.attrs.label === false ? null : m('label.label-container', { for: vnode.state.id }, [vnode.state.label]),
			vnode.attrs.type === 'checkbox' ? m('label.custom-control-label', { for: vnode.state.id }, [vnode.state.label]) : null,
			m(
				'.input-group',
				{
					className:
						vnode.attrs.isStatic && vnode.attrs.align === 'right'
							? 'justify-content-end'
							: vnode.attrs.resource.errors &&
							  vnode.attrs.resource.errors()[`${vnode.attrs.objectPath ? vnode.attrs.objectPath + '.' : ''}${vnode.attrs.property}`]
							? 'has-error'
							: null,
				},
				[
					vnode.attrs.isStatic
						? vnode.attrs.type !== 'upload'
							? m('p.form-control-static', { id: vnode.state.id }, [
									vnode.attrs.link ? m('a', vnode.attrs.link, vnode.state.value) : vnode.state.value,
							  ])
							: m('.form-control-static', [vnode.attrs.imgUrl ? m('img.img-fluid', { src: vnode.attrs.imgUrl }) : null])
						: vnode.attrs.isReadOnly
						? m('p.form-control-readonly', { id: vnode.state.id }, [vnode.state.value])
						: vnode.attrs.customInput
						? vnode.attrs.customInput
						: vnode.attrs.type === 'checkbox'
						? null
						: m(input, vnode.attrs),
					vnode.attrs.append ? vnode.attrs.append : null,

					vnode.attrs.resource.errors &&
					vnode.attrs.resource.errors()[`${vnode.attrs.objectPath ? vnode.attrs.objectPath + '.' : ''}${vnode.attrs.property}`]
						? m(
								'.input-errors' + (vnode.attrs.append ? '.has-append' : ''),
								m(
									'button',
									{
										oncreate: vnode => {
											$(vnode.dom).tooltip({ trigger: 'hover', animation: false })
										},
										onremove: vnode => {
											$(vnode.dom).tooltip('dispose')
										},
										'data-toggle': 'tooltip',
										type: 'button',
										'data-placement': 'bottom',
										'data-html': 'true',
										title:
											'<small>' +
											map(
												vnode.attrs.resource.errors &&
													vnode.attrs.resource.errors()[
														`${vnode.attrs.objectPath ? vnode.attrs.objectPath + '.' : ''}${vnode.attrs.property}`
													],
												(err, i) => {
													return err + (i !== 0 ? '<br>' : '')
												}
											) +
											'</small>',
									},
									m(icon, { iconName: 'warning' })
								)
						  )
						: null,
				]
			),
		]
	)
}
export default formGroup
