﻿// Libraries
import m from 'mithril'

// Models
import User from '../models/User'

// Components
import { card } from 'bootstrap-mithril'
import formGroup from '../components/formGroup'
import saveBtn from '../components/saveBtn'
import loading from '../components/loading'
import { actionbar } from '../components/actionbar'

var pageUser = {}

pageUser.oninit = (vnode) => {
	User.get(vnode.attrs.user().id)
}

pageUser.view = (vnode) => {
	return User.current() && actionbar
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'Account',
						},
						{
							text: 'User details',
						},
					],
					buttons: [m(saveBtn, { resource: User })],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col-sm-6', [
							m(card, {
								header: 'User Details',
								body: [
									m(formGroup, {
										label: 'First Name',
										type: 'textfield',
										resource: User,
										property: 'firstName',
										required: true,
									}),
									m(formGroup, {
										label: 'Last Name',
										type: 'textfield',
										resource: User,
										property: 'lastName',
										required: true,
									}),
									m(formGroup, {
										label: 'Email',
										type: 'textfield',
										resource: User,
										property: 'email',
										subtype: 'email',
										required: true,
									}),
									m(formGroup, {
										label: 'Phone',
										type: 'textfield',
										resource: User,
										property: 'phoneNumber',
									}),
									m(formGroup, {
										label: 'IMDB / LinkedIn Profile',
										type: 'textfield',
										resource: User,
										property: 'imdbLinkedIn',
									}),
									m(formGroup, {
										label: 'Occupation',
										type: 'textfield',
										resource: User,
										property: 'occupation',
									}),
									m(formGroup, {
										label: 'Experience',
										type: 'textfield',
										resource: User,
										property: 'experience',
									}),
									m(formGroup, {
										label: 'Email for BIFA correspondence',
										type: 'textfield',
										resource: User,
										property: 'emailForBifaInfo',
										subtype: 'email',
										required: false,
									}),
									m(formGroup, {
										label: 'Email for distributor correspondence',
										type: 'textfield',
										resource: User,
										property: 'emailForVotingInfo',
										subtype: 'email',
										required: false,
									}),
									m(formGroup, {
										label: 'Postal address',
										type: 'textarea',
										resource: User,
										property: 'distributorsPostalAddress',
										required: false,
										rows: 4,
									}),
								],
							}),
						]),
						m('.col-sm-6', []),
					]),
				]),
		  ]
		: m(loading)
}

export default pageUser
