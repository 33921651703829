﻿import m from 'mithril'
import prop from 'mithril/stream'

var Subgroup = {}

Subgroup.list = prop()
Subgroup.getList = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/subgroups',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Subgroup.list)
}

export default Subgroup
