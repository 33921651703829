﻿import m from 'mithril'
import prop from 'mithril/stream'
import Account from '../models/Account'
import notifications from '../components/notifications'
import loading from '../components/loading'
import { card, formgroup } from 'bootstrap-mithril'
import setValue from '../helpers/setValue'

const loginForm = {}

loginForm.oninit = (vnode) => {
	vnode.state.loggingIn = prop(false)
	vnode.state.dto = {
		Email: null,
		Password: null,
	}

	vnode.state.submitForm = (e) => {
		e.preventDefault()
		vnode.state.loggingIn(true)
		Account.login(
			`username=${encodeURIComponent(vnode.state.dto.Email)}&password=${
				vnode.state.dto.Password
			}&grant_type=password&client_id=BIFAvoting&client_secret=first-party`
		)
			.then((data) => {
				localStorage.setItem('accessToken', data.access_token)
				Account.identity().then(() => {
					if (Account.user().isChangePassword) {
						m.route.set('/me/changepasswordexpired')
					} else {
						m.route.set('/')
					}
				})
			})
			.catch((e) => {
				vnode.state.loggingIn(false)
				return e
					? notifications.add({
							type: 'Error',
							title: 'Login Issue',
							message: e.error_description,
					  })
					: null
			})
	}
}

loginForm.view = (vnode) => {
	return m(card, {
		classes: ['login-form', 'mt-5'],
		header: 'Login',
		body: m(
			'form',
			{
				onsubmit: vnode.state.submitForm,
			},
			[
				m(formgroup, {
					label: 'Email',
					input: m('input.form-control', {
						id: 'email',
						type: 'email',
						onchange: setValue(vnode.state.dto, 'Email'),
						value: vnode.state.dto.Email,
						required: 'required',
					}),
					inputId: 'email',
				}),
				m('.form-group', [
					m(
						'label',
						{
							for: 'password',
						},
						'Password'
					),
					m('input.form-control', {
						id: 'password',
						type: 'password',
						onchange: setValue(vnode.state.dto, 'Password'),
						value: vnode.state.dto.Password,
						required: 'required',
						autocomplete: 'current-password',
					}),
				]),
				vnode.state.loggingIn()
					? m(loading)
					: m(
							'button.btn.btn-primary',
							{
								type: 'submit',
							},
							['Log in']
					  ),
			]
		),
		footer: m(
			'a',
			{
				oncreate: m.route.link,
				href: '/forgottenpassword',
			},
			'Forgotten your password?'
		),
	})
}

export default loginForm
