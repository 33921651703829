﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'

// Models
import User from '../models/User'

// Components
import { card } from 'bootstrap-mithril'
import formGroup from '../components/formGroup'
import icon from '../components/icon'
import loading from '../components/loading'
import { actionbar } from '../components/actionbar'
import Player from '@vimeo/player'

import variables from '../helpers/variables'

var page = {}

page.oninit = (vnode) => {
	User.get(vnode.attrs.user().id)
	vnode.state.ended = prop(false)
}

page.view = (vnode) => {
	return User.current() && actionbar
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'Account',
						},
						{
							text: 'Unconscious Bias Training',
						},
					],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col-sm-12', [
							User.current().isUBTrainingLastYearDone ||
							User.current().unconsciousBiasStatus === '2019 voting year' ||
							User.current().unconsciousBiasStatus === '2020 voting year' ||
							User.current().unconsciousBiasStatus === '2021 voting year' ||
							User.current().unconsciousBiasStatus === '2022 voting year' ||
							User.current().unconsciousBiasStatus === '2023 voting year'
								? m(card, {
										header: 'Refresher Course',
										body: [
											User.current().isUBTrainingDone
												? m(
														'p',
														'You have completed the unconscious bias training. There is a refresher course here that you can access at any time.'
												  )
												: m(
														'p',
														`Thank you for completing the unconscious bias training last year. We have created a short video for you to watch, to refresh your memory of last year's training before you complete your ${variables.year} voting.`
												  ),
											!User.current().isUBTrainingDone &&
												m(
													'p',
													'To start the refresher, please watch the video below until the end and then click on the "Confirm Training Complete" button which will appear when the film has ended.'
												),
											m('div.player', {
												oncreate: (e) => {
													const player = new Player(e.dom, {
														url: 'https://vimeo.com/753765371/83a92f5ffe',
														responsive: true,
														loop: false,
														muted: false,
													})

													// player.on('play', function() {
													// 	vnode.state.ended(true)
													// 	m.redraw()
													// 	$('html, body').animate(
													// 		{
													// 			scrollTop: $('.course-complete').offset().top,
													// 		},
													// 		200
													// 	)
													// })
													player.on('ended', function () {
														vnode.state.ended(true)
														m.redraw()
														$('html, body').animate(
															{
																scrollTop: $('.course-complete').offset().top,
															},
															200
														)
													})
												},
											}),
										],
								  })
								: null,
						]),
						vnode.state.ended() || (User.current().isUBTrainingLastYearDone && User.current().isUBTrainingDone)
							? m('.col-sm-12.mt-3.course-complete', [
									m(card, {
										header: 'Course complete',
										body: [
											!User.current().isUBTrainingDone &&
												m(
													'p',
													"Thank you for completing this year's refresher course. Please download the PDFs below and click to confirm you have watched the video."
												),
											m('p', [
												m(
													'a.mr-3.btn.btn-info',
													{
														href: 'https://storage.googleapis.com/bifa-film/web/2022/09/bifa_unconscious_bias_toolkit_2022_23.pdf',
														target: '_blank',
													},
													[m(icon, { iconName: 'file' }), m('span.ml-1', 'BIFA Unconscious Bias Toolkit')]
												),
												m(
													'a.mr-3.btn.btn-info',
													{
														href: 'https://storage.googleapis.com/bifa-film/web/2022/09/bifa_participant_action_plan_2022_23.pdf',
														target: '_blank',
													},
													[m(icon, { iconName: 'file' }), m('span.ml-1', 'BIFA Participant Action Plan')]
												),
											]),
											!User.current().isUBTrainingDone &&
												m(
													'button.btn.btn-success',
													{
														type: 'submit',
														onclick: () => {
															User.completeTraining()
														},
													},
													[m(icon, { iconName: 'check-circle' }), m('span.ml-1', 'Confirm Training Complete')]
												),
										],
									}),
							  ])
							: null,
					]),
				]),
		  ]
		: m(loading)
}

export default page
