﻿import m from 'mithril'
import { map, compact } from 'lodash'
import icon from './icon'
import truncateMiddle from '../helpers/truncateMiddle'

var breadcrumb = {}
breadcrumb.view = vnode => {
	var l = vnode.attrs.breadcrumbs ? compact(vnode.attrs.breadcrumbs).length : 0
	return vnode.attrs.breadcrumbs
		? m('ol.breadcrumb', [
				m('li.breadcrumb-item', { title: 'Dashboard' }, m('a', { href: '/', oncreate: m.route.link }, m(icon, { iconName: 'dashboard' }))),
				map(compact(vnode.attrs.breadcrumbs), (b, i) => {
					return m('li.breadcrumb-item', { className: i === l - 1 ? 'active' : '' }, [
						b.href
							? m('a.title', { href: b.href, oncreate: m.route.link }, [
									b.icon ? m(icon, { iconName: b.icon }) : null,
									truncateMiddle(b.text, 36, 10),
							  ])
							: m('span.title', [b.icon ? m(icon, { iconName: b.icon }) : null, truncateMiddle(b.text, 36, 10)]),
					])
				}),
		  ])
		: null
}

export default breadcrumb
