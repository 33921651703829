﻿function swipe(el, options) {
    options = options || {}

    el.addEventListener("touchstart", onTouchStart, false);
    el.addEventListener("touchmove", onTouchMove, false);
    el.addEventListener("touchend", onTouchEnd, false);

    var leftOffset,
        leftX,
        overallMovement;

    function onTouchStart(event) {
        const element = event.currentTarget;
        leftX = element.offsetLeft; //moght be an issue
        const touches = event.changedTouches;
        leftOffset = touches[0].clientX - leftX;
    }

    function onTouchMove(event) {
        event.preventDefault();
        const element = event.currentTarget;
        const touches = event.changedTouches;
        const leftMovement = touches[0].clientX - leftOffset;
        element.style.marginLeft = leftMovement + "px";

        overallMovement = Math.abs(leftMovement - leftX);

        options.onchange(element, overallMovement);

    }

    function onTouchEnd(event) {
        const element = event.currentTarget;
        if (overallMovement < 200) {
            element.style.marginLeft = leftX + "px";
            element.style.opacity = 1;
        } else {
            element.style.display = "none";
        }
    }
}

export default swipe