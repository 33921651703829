
import m from "mithril"
import icon from "./icon"

var saveBtnSubmit = {}
saveBtnSubmit.view = (vnode) => {
	return m("button.btn.btn-save", {
		type: "submit",
		className: vnode.attrs.resource.hasChanges() ? "btn-success" : null,
		disabled: !vnode.attrs.resource.hasChanges()
	}, [
		m(icon, { iconName: vnode.attrs.resource.hasChanges() ? "exclamation-circle" : "check-circle" }),
		!vnode.attrs.resource.hasChanges() ? "Saved" : "Save changes"
	])
}
export default saveBtnSubmit