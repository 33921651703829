﻿import m from 'mithril'
import prop from 'mithril/stream'
import { cloneDeep, isEqual } from 'lodash'

import notifications from '../components/notifications'

var VoterFilm = {}

VoterFilm.current = prop()
VoterFilm.original = prop()
VoterFilm.clear = () => {
	VoterFilm.current(false)
	VoterFilm.original(false)
}
VoterFilm.getByFilmId = (filmId) => {
	VoterFilm.current = prop()
	VoterFilm.original = prop()

	return m
		.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/voterfilms/${filmId}`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((clean) => {
			VoterFilm.current(clean)
			VoterFilm.original(cloneDeep(clean))
		})
}

VoterFilm.discard = () => {
	VoterFilm.current(cloneDeep(VoterFilm.original()))
}
VoterFilm.hasChanges = () => {
	return !isEqual(VoterFilm.current(), VoterFilm.original())
}

VoterFilm.saving = prop(false)

VoterFilm.save = () => {
	VoterFilm.saving(true)
	notifications.add({
		title: 'Saving',
		type: 'saving',
	})
	return m
		.request({
			method: 'PUT',
			url: `https://bifa-api.azurewebsites.net/voting/api/voterfilms/${VoterFilm.current().id}`,
			data: VoterFilm.current(),
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((clean) => {
			VoterFilm.current(clean)
			VoterFilm.original(cloneDeep(clean))
			notifications.clear()
			notifications.add({
				title: 'Feedback Saved',
				type: 'success',
			})
			VoterFilm.saving(false)
		})
}

export default VoterFilm
