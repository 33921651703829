﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { map, forEach, filter, isArray, sumB } from 'lodash'
import DateFormat from 'dateformat'

// Components
import icon from './icon'
import loading from './loading'
import input from './input'
import pagination from './pagination'
import formatMoney from '../helpers/formatMoney'
import tooltip from '../helpers/tooltip'
import getObjectValue from '../helpers/getObjectValue'

var table = {}
table.oninit = (vnode) => {
	vnode.state.isArray = vnode.attrs.resource ? isArray(vnode.attrs.resource) : true
}
table.view = (vnode) => {
	return [
		m(
			'table.table.table-flush',
			{
				className: (vnode.attrs.isGrid ? 'table-grid ' : '') + (vnode.attrs.className ? vnode.attrs.className : ''),
			},
			[
				m('thead', [
					m('tr', [
						map(vnode.attrs.columns, (cell, ix) => {
							return cell
								? m(
										'th',
										{
											style: cell.width ? `width:${cell.width}` : '',
											className: filter([cell.className, cell.align ? 'text-' + cell.align : ''], (val) => val).join(
												' '
											),
										},
										[cell.label || cell.name]
								  )
								: null
						}),
					]),
					vnode.attrs.filterRow
						? m('tr.tr-filter', [
								map(vnode.attrs.columns, (cell, ix) => {
									return cell
										? m(
												'th',
												{
													style: cell.width ? `width:${cell.width}` : '',
												},
												cell.filter
										  )
										: null
								}),
						  ])
						: null,
				]),
				vnode.state.isArray || vnode.attrs.resource.list()
					? m('tbody', [
							map(vnode.state.isArray ? vnode.attrs.resource : vnode.attrs.resource.list().items, (item, rowIx) => {
								vnode.attrs.rowCallback ? vnode.attrs.rowCallback(item, rowIx) : null
								if (vnode.attrs.filter && !vnode.attrs.filter(item)) {
									return null
								}
								if (vnode.attrs.liveFilter) {
									for (var f in vnode.attrs.liveFilter) {
										if (vnode.attrs.liveFilter[f]) {
											if (f.indexOf('_') > -1) {
												var paths = f.split('_')
												var found = false
												forEach(item[paths[0]], (itemRow) => {
													if (itemRow[paths[1]] + '' === vnode.attrs.liveFilter[f]) {
														found = true
													}
												})
												if (!found) {
													return null
												}
											} else if (item[f].toLowerCase().indexOf(vnode.attrs.liveFilter[f]) === -1) return null
										}
									}
								}
								return [
									m(
										'tr',
										{
											key: item.id,
											'data-id': item.id,
											className: item.className || '',
											onmousedown: vnode.attrs.rowClick
												? (e) => {
														if (e.target.localName.toUpperCase() === 'TD') {
															e.target.parentNode.className = filter([item.className, 'active'], (val) => val).join(' ')
														}
												  }
												: null,
											onmouseup: vnode.attrs.rowClick
												? (e) => {
														if (e.target.localName.toUpperCase() === 'TD') {
															e.target.parentNode.className = item.className || ''
														}
												  }
												: null,
											onclick: (e) => {
												if (vnode.attrs.rowClick !== false) {
													if (vnode.attrs.rootUrl && !e.ctrlKey) {
														m.route.set(
															vnode.attrs.rootUrl +
																(vnode.attrs.linkId ? getObjectValue(item, vnode.attrs.linkId) : item.id)
														)
													}
												}
											},
										},
										[
											map(vnode.attrs.columns, (cell, ix) => {
												return cell
													? m(
															'td',
															{
																className: filter(
																	[cell.className, cell.align ? 'text-' + cell.align : ''],
																	(val) => val
																).join(' '),
																style: cell.width ? `width:${cell.width}` : '',
															},
															[
																cell.showLabel ? m('span.grid-only', cell.label || cell.name) : null,
																cell.link
																	? m(
																			'a',
																			{
																				href: cell.linkExternal
																					? getLink(getCellData(item, cell))
																					: vnode.attrs.rootUrl +
																					  (vnode.attrs.linkId ? getObjectValue(item, vnode.attrs.linkId) : item.id),
																				target: cell.linkExternal ? '_blank' : '_self',
																				onclick: (e) => {
																					if (e.target.href && !cell.linkExternal) {
																						e.preventDefault()
																						e.stopPropagation()
																						m.route.set(e.target.href)
																					}
																				},
																				className: cell.className,
																			},
																			[
																				getCellData(item, cell),
																				cell.linkExternal
																					? m(icon, {
																							iconName: 'external-link',
																					  })
																					: null,
																			]
																	  )
																	: cell.template
																	? cell.template(getCellData(item, cell), item)
																	: cell.isEditable
																	? m('.custom-control.custom-checkbox', [
																			m(input, {
																				type: cell.type,
																				subtype: cell.subtype,
																				required: cell.required,
																				resource: vnode.attrs.resource,
																				property: cell.property || cell.name,
																				objectPath: `${cell.objectPath}.${rowIx}`,
																				isList: true,
																				choices: map(cell.choices, (oitem) => {
																					return {
																						value: oitem[cell.choicesValueProperty],
																						label: oitem[cell.choicesLabelProperty],
																						selected:
																							oitem[cell.choicesValueProperty] === item[cell.choicesSelectedProperty],
																					}
																				}),
																			}),
																			cell.type === 'checkbox'
																				? m('label.custom-control-label', {
																						for: `i_${cell.objectPath}.${rowIx}_${cell.property || cell.name}`,
																				  })
																				: null,
																	  ])
																	: getCellData(item, cell),
															]
													  )
													: null
											}),
										]
									),
								]
							}),
					  ])
					: m(loading),
				,
				(vnode.state.isArray || vnode.attrs.resource.list()) && vnode.attrs.footer
					? m('tfoot', [
							m('tr', [
								map(vnode.attrs.columns, (cell, ix) => {
									return cell
										? m(
												'td',
												{
													className: filter(
														[cell.className, cell.footerAlign ? 'text-' + cell.footerAlign : null],
														(val) => val
													).join(' '),
												},
												[
													cell.sum
														? cell.type === 'currency'
															? formatMoney(
																	sumBy(
																		vnode.state.isArray ? vnode.attrs.resource : vnode.attrs.resource.list().items,
																		(item) => {
																			if (cell.sumFilter) {
																				return item[cell.sumFilter] ? getCellData(item, cell, true) : 0
																			} else return getCellData(item, cell, true)
																		}
																	)
															  )
															: sumBy(
																	vnode.state.isArray ? vnode.attrs.resource : vnode.attrs.resource.list().items,
																	(item) => {
																		if (cell.sumFilter) {
																			return item[cell.sumFilter] ? getCellData(item, cell, true) : 0
																		} else return getCellData(item, cell, true)
																	}
															  )
														: cell.footer,
												]
										  )
										: null
								}),
							]),
					  ])
					: null,
			]
		),
		m('.d-flex.justify-content-center.mt-3', [
			!vnode.state.isArray && vnode.attrs.resource.list() && vnode.attrs.resource.list().count
				? m(pagination, {
						resource: vnode.attrs.resource,
						sort: vnode.attrs.sort,
						selectedItemId: vnode.attrs.selectedItemId,
				  })
				: null,
		]),
	]
}

function getCellData(row, cell, noFormat) {
	var value = row
	forEach(cell.name.split('.'), (path) => {
		value = value ? value[path] : ''
	})
	if (noFormat) {
		return value
	} else {
		if (cell.type === 'icon-boolean') {
			return m(icon, { iconName: value ? cell.iconTrue || 'done' : cell.iconFalse || '' })
		} else if (cell.type === 'datetime') {
			return DateFormat(Date.parse(value), cell.format || 'dd/mm/yyyy HH:MM:ss')
		} else if (cell.type === 'image') {
			return value ? m('img', { src: value + '?h=160' }) : m(icon, { iconName: cell.nullIcon || 'square-o' })
		} else if (cell.type === 'currency') {
			return formatMoney(value)
		} else return value
	}
}

function getLink(link) {
	if (link.indexOf('http') === 0) return link
	else return 'http://' + link
}

export default table
