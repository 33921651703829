﻿import { forEach } from 'lodash'

function getObjectValue(resource, objectPath) {
	var r = resource
	if (objectPath && r) {
		forEach(objectPath.split('.'), path => {
			r = r[path]
		})
	}
	return r
}

export default getObjectValue
