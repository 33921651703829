﻿import m from 'mithril'
import prop from 'mithril/stream'

var Account = {}

Account.user = prop({ IsSuperAdmin: false })
Account.vm = prop()

Account.identity = () => {
	return m
		.request({
			method: 'GET',
			url: 'https://bifa-api.azurewebsites.net/account/users/me',
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((identity) => {
			if (identity) {
				identity.isMember = identity.roles.includes('Voter')
				Account.user(identity)
			} else Account.logout()
		})
		.catch((e) => (e ? Account.logout() : null))
}

Account.login = (user) => {
	return m.request({
		method: 'POST',
		url: 'https://bifa-api.azurewebsites.net/connect/token',
		data: user,
		config: (xhr) => {
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
		},
		serialize: (data) => data,
	})
}

Account.logout = () => {
	localStorage.setItem('accessToken', '')
	m.route.set('/login')
}

Account.forgotPassword = (vm) => {
	localStorage.setItem('accessToken', '')
	return m.request({
		method: 'POST',
		url: 'https://bifa-api.azurewebsites.net/account/public/recoverpassword',
		data: vm,
	})
}

Account.resetPassword = (vm) => {
	localStorage.setItem('accessToken', '')
	return m.request({
		method: 'POST',
		url: `https://bifa-api.azurewebsites.net/account/public/resetpassword`,
		data: vm,
	})
}

Account.changePassword = (vm) => {
	return m.request({
		method: 'POST',
		url: '/api/account/changepassword',
		data: vm,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
		},
	})
}
export default Account
