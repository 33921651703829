﻿import m from 'mithril'
import forgotForm from '../account/forgot'
import icon from '../components/icon'

var pageForgottenPassword = {}

pageForgottenPassword.view = vnode => {
	return m('.container-fluid', [m('.row', [m('.col-sm-8.col.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4', [m(forgotForm)])])])
}

export default pageForgottenPassword
