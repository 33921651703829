// Libraries
import m from 'mithril'
import prop from 'mithril/stream'

import User from '../models/User'
// Components
import input from '../components/input'

const page = {}

page.oninit = (vnode) => {
	vnode.state.flipped = prop(false)
}
page.view = (vnode) => {
	return [
		m('.container-fluid.pt-1', [
			vnode.attrs.user().isMember
				? m(
						'.flip-card',
						{
							className: vnode.state.flipped() ? 'flipped' : '',
						},
						[
							m('.flip-card-inner', [
								m('.flip-card-front', [
									m('img.position-absolute', {
										style: 'bottom:1rem;right:1rem;width:30%',
										src: '/img/logo.svg',
										alt: 'BIFA',
									}),
									m('.row.no-gutters.h-100.align-content-center.align-content-sm-stretch', [
										m('.col-sm-4.avatar', [
											vnode.attrs.user().Avatar
												? m('img.rounded-circle.m-3', {
														src: `${vnode.attrs.user().Avatar}?fm=auto&w=240&h=240&fit=crop&crop=faces`,
												  })
												: m(
														'a.add-avatar.rounded-circle.m-3.bg-light',
														{
															href: '/me',
															oncreate: m.route.link,
														},
														'+'
												  ),
										]),
										m('.col-sm-8.membership-details', [
											m('h3.mt-3.text-uppercase', vnode.attrs.user().FullName),
											m('p', ['BIFA MEMBER', m('.small.text-muted', `EXP: 12/${new Date().getFullYear()}`)]),
											m(
												'a.text-white.mb-3.small',
												{
													href: '#',
													onclick: (ev) => {
														ev.preventDefault()
														vnode.state.flipped(true)
													},
												},
												'Terms & Conditions'
											),
										]),
									]),
								]),
								m('.flip-card-back', [
									m('.flex.flex-column.justify-content-between.', [
										m('h4.mt-3', 'Terms & Conditions'),
										m('div', [
											m('p.text-center.m-0', 'Monday - Thursday only'),
											m('p.text-center.m-0', 'One ticket per voter, upon presentation of their BIFA card'),
											m('p.text-center.m-0', 'Walk-up only (no-online pre-book)'),
											m('p.text-center.m-0', 'First come, first served basis'),
											m('p.text-center.m-0', 'Available 1 hr before screening'),
										]),
										m(
											'button.btn.btn-secondary.btn-sm.mt-4',
											{
												type: 'button',
												onclick: () => {
													vnode.state.flipped(false)
												},
											},
											'Back'
										),
									]),
								]),
							]),
						]
				  )
				: m(
						'.alert.alert-danger',
						'You are not a valid member or your membership has expired. Please contact BIFA team.'
				  ),
		]),
	]
}

export default page
