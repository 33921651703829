﻿import getResourceObject from './getResourceObject'

function assignValue(resource, property, objectPath) {
	return function (event) {
		const target = event.currentTarget || event.target
		let newValue
		if (target.type === 'checkbox') {
			newValue = target.checked
		} else if (target.multiple) {
			newValue = Array.prototype.filter
				.call(target.options, (option) => option.selected)
				.map((option) => option.value)
				.join(', ')
		} else if (target.contentEditable === 'true') {
			newValue = target.textContent
		} else {
			newValue = target.value
		}

		getResourceObject(resource, objectPath)[property] = newValue
	}
}

export default assignValue
