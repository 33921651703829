﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { find, map, uniq, filter, groupBy, forEach, maxBy, sortBy } from 'lodash'

// Models
import User from '../models/User'
import Film from '../models/Film'
import Round from '../models/Round'
import VotingCategory from '../models/VotingCategory'

// Components
import { card } from 'bootstrap-mithril'
import { DateTime } from 'luxon'
import table from '../components/table'
import loading from '../components/loading'
import icon from '../components/icon'
import { actionbar } from '../components/actionbar'
import tooltip from '../helpers/tooltip'
import { categoryTag } from '../components/categoryTag'

var page = {}

page.liveFilter = prop()
page.oninit = (vnode) => {
	vnode.state.roundOpen = false
	vnode.state.status = { total: 0, abstaining: 0, none: 0, voted: 0, submittable: false, subgroup: 0 }
	if (!VotingCategory.list()) VotingCategory.getList()
	if (!User.subgroups()) User.getSubgroups(vnode.attrs.user().id)
	Round.get(vnode.attrs.id).then(() => {
		Round.getFilms(Round.current().id)
		Round.getEntries(Round.current().id)
		page.reloadState(vnode)
	})
}
page.reloadState = (vnode) => {
	vnode.state.status = {
		total: Round.current().roundJuror.roundJurorRoundVotingCategories.length,
		abstaining: filter(Round.current().roundJuror.roundJurorRoundVotingCategories, (s) => {
			return s.isAbstaining
		}).length,
		none: filter(Round.current().roundJuror.roundJurorRoundVotingCategories, (s) => {
			return s.isVotingForNone
		}).length,
		voted: filter(Round.current().roundJuror.roundJurorRoundVotingCategories, (s) => {
			return s.votes.length && !s.isAbstaining && !s.isVotingForNone
		}).length,
		submittable:
			filter(Round.current().roundJuror.roundJurorRoundVotingCategories, (s) => {
				let minVotes = 1
				forEach(s.roundVotingCategory.rules, (item) => {
					if (item.RuleTypeId === 2) minVotes = item.value
				})
				return s.isAbstaining || s.votes.length >= minVotes || s.isVotingForNone
			}).length /
				Round.current().roundJuror.roundJurorRoundVotingCategories.length ===
			1,
		subgroup: filter(Round.current().roundJuror.roundJurorRoundVotingCategories, (r) =>
			find(User.subgroups(), ['id', r.roundVotingCategory.votingCategory.subgroupId])
		).length,
	}
	vnode.state.globalErrors = false
	vnode.state.categories = sortBy(
		map(Round.current().roundJuror.roundJurorRoundVotingCategories, (item) => {
			return {
				id: item.roundVotingCategory.id,
				name: item.roundVotingCategory.votingCategory.name,
				votes: item.votes,
				isAbstaining: item.isAbstaining,
				isVotingForNone: item.isVotingForNone,
				subgroup: User.subgroups()
					? find(User.subgroups(), ['id', item.roundVotingCategory.votingCategory.subgroupId])
					: false,
				sortOrder: item.roundVotingCategory.votingCategory.sortOrder,
				rules: item.roundVotingCategory.rules,
			}
		}),
		['Subgroup', 'SortOrder']
	)
	vnode.state.roundOpen =
		DateTime.fromISO(Round.current().startDate) < DateTime.now() &&
		DateTime.fromISO(Round.current().endDate) >= DateTime.now() &&
		!Round.current().roundJuror.isComplete
}
page.onupdate = (vnode) => {
	if (Round.current()) {
		document.title = `${Round.current().name} - Rounds - BIFA Voting`
	}
}

page.view = (vnode) => {
	let roundStart = Round.current() ? DateTime.fromISO(Round.current().startDate) : 0
	let roundEnd = Round.current() ? DateTime.fromISO(Round.current().endDate) : 0

	return Round.current() && VotingCategory.list()
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: Round.current().name,
						},
					],
					buttons: [
						vnode.state.roundOpen &&
							m(
								'button.btn',
								{
									className: Round.current().roundJuror.isAbstaining ? 'btn-muted' : 'btn-warning',
									type: 'button',
									onclick: () => {
										Round.current().roundJuror.isAbstaining ? Round.unabstain() : Round.abstain()
									},
								},
								Round.current().roundJuror.isAbstaining ? 'Remove round abstention' : 'Abstain from round'
							),
						m(
							'button.btn.btn-info',
							{
								type: 'button',
								onclick: () => {
									Round.get(vnode.attrs.id)
								},
							},
							'Refresh'
						),
					],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col', [
							m('.jumbotron.position-relative', [
								m('h1', Round.current().name),
								m('p.lead', Round.current().description),
								m('hr'),
								roundStart > DateTime.now()
									? m(
											'p',
											`This round has not yet opened. It is scheduled to start on: ${roundStart.toLocaleString(
												DateTime.DATETIME_MED
											)}`
									  )
									: m('p', `Round ends: ${roundEnd.toLocaleString(DateTime.DATETIME_MED)}`),
								Round.current().roundJuror.isAbstaining
									? m('.alert.alert-warning', 'You are abstaining from this round.')
									: null,
								m(
									'a.btn.btn-info',
									{
										href: `/rounds/${vnode.attrs.id}/films`,
										oncreate: m.route.link,
									},
									'View all films entered into this round'
								),
								m('.progress.mt-3', [
									m(
										'.progress-bar.bg-success',
										Object.assign({}, tooltip(), {
											role: 'progressbar',
											style: `width:${(vnode.state.status.voted / vnode.state.status.total) * 100}%`,
											title: `Voted`,
										}),
										vnode.state.status.voted
									),
									m(
										'.progress-bar.bg-primary',
										Object.assign({}, tooltip(), {
											role: 'progressbar',
											style: `width:${(vnode.state.status.none / vnode.state.status.total) * 100}%`,
											title: `Voting for none`,
										}),
										vnode.state.status.none
									),
									m(
										'.progress-bar.bg-warning',
										Object.assign({}, tooltip(), {
											role: 'progressbar',
											style: `width:${(vnode.state.status.abstaining / vnode.state.status.total) * 100}%`,
											title: `Abstaining`,
										}),
										vnode.state.status.abstaining
									),
									m(
										'.progress-bar.bg-secondary',
										Object.assign({}, tooltip(), {
											role: 'progressbar',
											style: `width:${
												(1 -
													(vnode.state.status.abstaining + vnode.state.status.none + vnode.state.status.voted) /
														vnode.state.status.total) *
												100
											}%`,
											title: `Pending`,
										}),
										vnode.state.status.total -
											(vnode.state.status.abstaining + vnode.state.status.none + vnode.state.status.voted)
									),
								]),
								Round.current().roundJuror.isComplete &&
									m('.alert.alert-success.mt-5', 'Thank you for submitting your votes.'),
							]),
							!Round.current().roundJuror.isComplete &&
								m(card, {
									bodyClasses: ['p-0'],
									header: [
										m('ul.nav.nav-tabs.card-header-tabs', { role: 'tablist' }, [
											User.subgroups() &&
												m('li.nav-item', {}, [
													m(
														'a.nav-link',
														{
															href: `#round-subgroups`,
															role: 'tab',
															'aria-controls': `round-subgroups`,
															'aria-selected': vnode.attrs.tab === 'subgroups' ? 'true' : 'false',
															'data-toggle': 'tab',
															className: vnode.attrs.tab === 'subgroups' ? 'active' : '',
															onclick: () => {
																history.pushState(null, '', `/rounds/${vnode.attrs.id}/subgroups`)
															},
														},
														['Your Subgroup Categories', m('span.badge.badge-info.ml-2', vnode.state.status.subgroup)]
													),
												]),
											User.subgroups() &&
												m('li.nav-item', {}, [
													m(
														'a.nav-link',
														{
															href: `#round-other`,
															role: 'tab',
															'aria-controls': `round-other`,
															'aria-selected': vnode.attrs.tab === 'other' ? 'true' : 'false',
															'data-toggle': 'tab',
															className: vnode.attrs.tab === 'other' ? 'active' : '',
															onclick: () => {
																history.pushState(null, '', `/rounds/${vnode.attrs.id}/other`)
															},
														},
														[
															'Other Categories',
															m('span.badge.badge-info.ml-2', vnode.state.status.total - vnode.state.status.subgroup),
														]
													),
												]),
											m('li.nav-item', {}, [
												m(
													'a.nav-link',
													{
														href: `#round-films`,
														role: 'tab',
														'aria-controls': `round-films`,
														'aria-selected': vnode.attrs.tab === 'films' ? 'true' : 'false',
														'data-toggle': 'tab',
														className: vnode.attrs.tab === 'films' ? 'active' : '',
														onclick: () => {
															history.pushState(null, '', `/rounds/${vnode.attrs.id}/films`)
														},
													},
													['Films', m('span.badge.badge-info.ml-2', Round.films() ? Round.films().length : null)]
												),
											]),
											m('li.nav-item', {}, [
												m(
													'a.nav-link',
													{
														href: `#round-summary`,
														role: 'tab',
														'aria-controls': `round-summary`,
														'aria-selected': vnode.attrs.tab === 'summary' ? 'true' : 'false',
														'data-toggle': 'tab',
														className: vnode.attrs.tab === 'summary' ? 'active' : '',
														onclick: () => {
															history.pushState(null, '', `/rounds/${vnode.attrs.id}/summary`)
														},
													},
													[
														'Summary',
														m(
															'span.badge.badge-success.ml-2',
															Object.assign({}, tooltip(), {
																title: 'Voted',
															}),
															vnode.state.status.voted
														),
														vnode.state.status.abstaining
															? m(
																	'span.badge.badge-warning.ml-2',
																	Object.assign({}, tooltip(), {
																		title: 'Abstaining',
																	}),
																	vnode.state.status.abstaining
															  )
															: null,
														vnode.state.status.none
															? m(
																	'span.badge.badge-primary.ml-2',
																	Object.assign({}, tooltip(), {
																		title: 'Voting for none',
																	}),
																	vnode.state.status.none
															  )
															: null,
													]
												),
											]),
										]),
									],
									body: [
										User.subgroups()
											? m('.tab-content', [
													User.subgroups()
														? m(
																'.tab-pane',
																{
																	id: `round-subgroups`,
																	role: 'tabpanel',
																	className: vnode.attrs.tab === 'subgroups' ? 'show active' : '',
																},
																[
																	roundTable(vnode, `round-subgroups`, (r) =>
																		find(User.subgroups(), ['id', r.roundVotingCategory.votingCategory.subgroupId])
																	),
																]
														  )
														: null,
													m(
														'.tab-pane',
														{
															id: 'round-other',
															role: 'tabpanel',
															className: vnode.attrs.tab === 'other' ? 'show active' : '',
														},
														[
															roundTable(
																vnode,
																`round-other`,
																(r) => !find(User.subgroups(), ['id', r.roundVotingCategory.votingCategory.subgroupId])
															),
														]
													),
													m(
														'.tab-pane',
														{
															id: 'round-films',
															role: 'tabpanel',
															className: vnode.attrs.tab === 'films' ? 'show active' : '',
														},
														[Round.entries() && filmsTable(vnode, `round-films`)]
													),
													m(
														'.tab-pane.p-3',
														{
															id: 'round-summary',
															role: 'tabpanel',
															className: vnode.attrs.tab === 'summary' ? 'show active' : '',
														},
														m(
															'.card-deck',
															map(vnode.state.categories, (item) => {
																let errors = []
																if (item.votes.length) {
																	const m = maxBy(item.votes, 'preference')
																	if (m.preference > item.votes.length) {
																		errors.push('Missing votes')
																	}
																	let minVotes = 1
																	forEach(item.rules, (item) => {
																		if (item.ruleTypeId === 2) minVotes = item.value
																	})
																	if (item.votes.length < minVotes) {
																		errors.push(`Missing votes - minimum ${minVotes} votes`)
																	}
																} else if (!(item.isAbstaining || item.isVotingForNone)) {
																	errors.push('Must vote or abstain')
																	vnode.state.roundOpen &&
																		errors.push(
																			m(
																				'button.btn.btn-sm.btn-warning',
																				{
																					onclick: () => {
																						if (item.isAbstaining)
																							Round.unabstainCategory(item.id).then((res) => {
																								item.isAbstaining = res
																								m.redraw()
																							})
																						else
																							Round.abstainCategory(item.id).then((res) => {
																								item.isAbstaining = res
																								m.redraw()
																							})
																					},
																				},
																				'Abstain'
																			)
																		)
																}
																if (errors.length) {
																	vnode.state.globalErrors = true
																}
																return m(card, {
																	classes: [
																		'card-sm',
																		'mb-3',
																		errors.length
																			? 'border-danger'
																			: item.isAbstaining
																			? 'bg-warning'
																			: item.isVotingForNone
																			? 'bg-primary text-white'
																			: 'border-success',
																	],
																	bodyClasses: [item.isAbstaining || item.isVotingForNone ? '' : 'p-0'],
																	header: m(
																		'a',
																		{
																			href: `/rounds/${vnode.attrs.id}/category/${item.id}`,
																			oncreate: m.route.link,
																			className: item.isAbstaining
																				? 'text-dark'
																				: item.isVotingForNone
																				? 'text-white'
																				: '',
																		},
																		item.name
																	),
																	body: [
																		item.isAbstaining
																			? m('h5.text-center', 'Abstaining')
																			: item.isVotingForNone
																			? m('h5.text-center', 'Voting for none')
																			: m('ul.list-group.list-group-flush', [
																					map(item.votes, (vote) => {
																						let entries = Round.entries()
																							? filter(Round.entries(), ['entryId', vote.entryId])
																							: false
																						let film =
																							entries && entries.length > 0
																								? find(Round.films(), ['id', entries[0].filmId])
																								: false
																						return (
																							vote.entryId &&
																							m(
																								'li.list-group-item.p-1',
																								`${vote.preference}. ${
																									Round.entries()
																										? (film ? film.title + ' - ' : '') +
																										  map(entries, 'person').join(', ')
																										: ''
																								}`
																							)
																						)
																					}),
																			  ]),
																		map(errors, (error) => {
																			return m('p.p-3.m-0.text-danger', error)
																		}),
																	],
																})
															})
														),
														!vnode.state.globalErrors &&
															vnode.state.status.submittable &&
															vnode.state.roundOpen &&
															m('.p-3.w-100.text-center', [
																m(
																	'button.btn.btn-success.btn-lg',
																	{
																		type: 'button',
																		onclick: () => {
																			Round.submit().then(() => {
																				vnode.state.roundOpen = false
																				m.route.set(`/rounds/${vnode.attrs.id}`)
																			})
																		},
																	},
																	'Submit votes'
																),
															])
													),
											  ])
											: m(loading),
									],
								}),
						]),
					]),
				]),
		  ]
		: m(loading)
}

function filmsTable(vnode, key) {
	return m(table, {
		key: key,
		resource: Round.films(),
		className: 'table-xs',
		rootUrl: `/films/`,
		doubleClick: false,
		liveFilter: page.liveFilter(),
		filterRow: true,
		rowClick: false,
		rowCallback: (item, _rowIx) => {
			item.className = item.isAssigned ? 'table-success' : item.isNew ? 'table-warning' : ''
		},
		columns: [
			{
				name: 'isNew',
				label: ' ',
				width: '33px',
				template: (value, item) => {
					if (item.isAssigned) return m(icon, { iconName: 'bookmark-o p-1 m-0', title: 'Assigned Film' })
					if (value)
						return m(icon, {
							iconName: 'star p-1 m-0',
							title: 'New film added: ' + DateTime.fromISO(item.CreatedAt).toLocaleString(DateTime.DATETIME_MED),
						})
				},
			},
			{
				name: 'title',
				label: 'Title',
				width: '25%',
				link: true,
				filter: m('.input-group', [
					m('input.form-control.input-filter-Title', {
						type: 'text',
						key: 'input-filter-Title-',
						value: page.liveFilter() ? page.liveFilter()['title'] : '',
						onkeyup: (e) => {
							const val = (e.currentTarget || e.target).value.toLowerCase()
							page.liveFilter({ title: val })
							e.redraw = true
						},
					}),
					m('.input-group-append', [
						m(
							'button.btn.btn-link',
							{
								type: 'button',
								onclick: () => {
									delete page.liveFilter()['title']
									$('.input-filter-Title').val('')
								},
							},
							m(icon, { iconName: 'close' })
						),
					]),
				]),
			},
			{
				name: 'screener',
				label: 'Screener',
				width: '62px',
			},
			{
				name: 'runningTime',
				label: 'Duration',
				align: 'center',
				width: '60px',
			},
			{
				name: 'entries',
				label: 'Entries',
				width: '20%',
				template: (value, item) => {
					let filmEntries = filter(Round.entries(), ['filmId', item.id])
					return map(
						groupBy(
							groupBy(filmEntries, (a) => a.entryId),
							(b) => b[0].votingCategory
						),
						(s) => {
							if (!s[0][0].subgroupIsHidden)
								return m(categoryTag, {
									category: {
										name: s[0][0].votingCategory,
										subgroupName: s[0][0].subgroupName,
										shortName: s[0][0].votingCategoryShortName,
									},
									entries: s,
								})
						}
					)
				},
				filter: m('.input-group', [
					m(
						'select.custom-select.input-filter-Entries_VotingCategoryId',
						{
							onchange: (e) => {
								const val = (e.currentTarget || e.target).value
								page.liveFilter({ entries_votingCategoryId: val })
								e.redraw = true
							},
						},
						[
							m('option', { value: '' }, 'Filter by category...'),
							map(VotingCategory.list(), (vc) => {
								return m('option', { value: vc.id }, vc.shortName)
							}),
						]
					),
					m('.input-group-append', [
						m(
							'button.btn.btn-link',
							{
								type: 'button',
								onclick: () => {
									delete page.liveFilter()['Entries_VotingCategoryId']
									$('.input-filter-Entries_VotingCategoryId').val('')
								},
							},
							m(icon, { iconName: 'close' })
						),
					]),
				]),
			},
			{
				name: 'isConflicted',
				label: 'Conflict?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					return m(
						'button.btn.btn-sm.btn-light',
						{
							type: 'button',
							id: `btn-conflicted-${item.id}`,
							key: `btn-conflicted-${item.id}`,
							onclick: () => {
								Film.toggleConflicted(item.id).then((x) => {
									item.isConflicted = x
								})
							},
						},
						value
							? m(icon, { iconName: 'check-circle bg-danger p-1 m-0 rounded-circle text-white' })
							: m(icon, { iconName: 'times-circle text-muted p-1 m-0' })
					)
				},
			},
			{
				name: 'isSeen',
				label: 'Seen?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					return m(
						'button.btn.btn-sm.btn-light',
						{
							type: 'button',
							id: `btn-seen-${item.id}`,
							key: `btn-seen-${item.id}`,
							onclick: () => {
								Film.toggleSeen(item.id).then((x) => {
									item.isSeen = x
								})
							},
						},
						value === null
							? m(icon, { iconName: 'question text-muted p-1 m-0' })
							: value
							? m(icon, { iconName: 'check-circle bg-success p-1 m-0 rounded-circle text-white' })
							: m(icon, { iconName: 'times text-danger p-1 m-0' })
					)
				},
			},
		],
	})
}

function roundTable(vnode, key, tblFilter) {
	return m(table, {
		key: key,
		resource: Round.current().roundJuror.roundJurorRoundVotingCategories,
		className: 'table-lg',
		rootUrl: `/rounds/${vnode.attrs.id}/category/`,
		linkId: 'roundVotingCategory.id',
		filter: tblFilter,
		rowClick: false,
		rowCallback: (item, _rowIx) => {
			item.className = item.isAbstaining
				? 'table-warning'
				: item.isVotingForNone
				? 'table-info'
				: item.votes.length
				? 'table-success'
				: ''
		},
		columns: [
			{
				name: 'roundVotingCategory.votingCategory.name',
				label: 'Category',
				link: true,
				className: 'h5 m-0',
			},
			{
				name: 'voterSC.totalEntries',
				label: 'Entries',
				align: 'center',
				width: '60px',
			},
			{
				name: 'voterSC.films',
				label: 'Films',
				align: 'center',
				width: '60px',
			},
			{
				name: 'voterSC.seenNotConflicted',
				label: 'Seen',
				align: 'center',
				width: '80px',
			},
			{
				name: 'voterSC.conflicted',
				label: 'Conflicted',
				align: 'center',
				width: '80px',
			},
			{
				name: 'voterSC.needed',
				label: 'Minimum',
				align: 'center',
				width: '80px',
			},
			{
				name: 'isAbstaining',
				label: 'Abstain?',
				align: 'center',
				width: '7%',
				template: (value, item) => {
					return (
						vnode.state.roundOpen &&
						m(
							'button.btn.btn-sm',
							{
								className: value ? 'btn-muted' : 'btn-warning',
								type: 'button',
								onclick: () => {
									if (value)
										Round.unabstainCategory(item.roundVotingCategory.id).then((res) => {
											item.isAbstaining = res
											page.reloadState(vnode)
											m.redraw()
										})
									else
										Round.abstainCategory(item.roundVotingCategory.id).then((res) => {
											item.isAbstaining = res
											page.reloadState(vnode)
											m.redraw()
										})
								},
							},
							value ? 'Remove' : 'Abstain'
						)
					)
				},
			},
			// {
			// 	name: 'IsVotingForNone',
			// 	label: 'None?',
			// 	align: 'center',
			// 	width: '5%',
			// 	template: (value, item) => {
			// 		if (value === null) return m(icon, { iconName: 'question text-muted p-1' })
			// 		else if (value) return m(icon, { iconName: 'check-circle bg-success p-1 rounded-circle text-white' })
			// 	},
			// },
			{
				name: 'votes',
				label: 'Votes',
				align: 'center',
				width: '60px',
				template: (value, item) => {
					let maxVotes = find(item.roundVotingCategory.rules, (r) => {
						return r.RuleTypeId === 1
					})
					return maxVotes ? `${value.length}/${maxVotes.value}` : value.length
				},
			},
			{
				name: 'status',
				label: 'Status',
				align: 'center',
				width: '200px',
				template: (value, item) => {
					if (item.isAbstaining) return 'Abstaining'
					else if (item.isVotingForNone) return 'Voting for none'
					else if (item.isComplete) return 'Complete'
					else if (
						item.voterSC &&
						item.voterSC.seenNotConflicted + (item.voterSC.includeConflictedAsSeen ? item.voterSC.conflicted : 0) <
							item.voterSC.needed
					)
						return 'Not seen enough films'
					else if (item.votes.length) return 'Votes in progress'
					else return 'Awaiting votes'
				},
			},
		],
	})
}

export default page
