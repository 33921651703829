﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { card } from 'bootstrap-mithril'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import dateFormat from 'dateformat'

// Components
import formGroup from '../components/formGroup'
import notifications from '../components/notifications'

const screenings = {}

screenings.oninit = vnode => {
	vnode.state.screening = {
		current: prop(false),
	}
}
screenings.view = vnode => {
	return [
		m('.container-fluid', [
			m('.row.main-content', [
				m('.col-md-9.mb-3', [
					m(card, {
						header: 'Screenings',
						body: [
							m('div', {
								oncreate: cal => {
									let calendar = new Calendar(cal.dom, {
										plugins: [dayGridPlugin, googleCalendarPlugin],
										googleCalendarApiKey: 'AIzaSyA4e1nVh7chkK64GUmbW5uF7KCvGTrs61Y',
										events: {
											googleCalendarId: 'bifa.org.uk_umahjgla4f43vbabo8gh8s0mds@group.calendar.google.com',
											editable: false,
										},
										eventClick: function(info) {
											info.jsEvent.preventDefault()
											vnode.state.screening.current({
												Start: info.event.start,
												Date: dateFormat(info.event.start, 'dddd, mmmm d, yyyy h:MM TT'),
												FilmTitle: info.event.title,
												Description: info.event.extendedProps.description,
												Venue: info.event.extendedProps.location,
											})
											m.redraw()
										},
									})

									calendar.render()
								},
							}),
						],
					}),
				]),
				m('.col-md-3', [
					m(card, {
						header: 'Selected screening',
						body: [
							vnode.state.screening.current()
								? m('.screening', [
										m(formGroup, {
											label: 'Date',
											isStatic: true,
											resource: vnode.state.screening,
											property: 'Date',
										}),
										m(formGroup, {
											label: 'Venue',
											isStatic: true,
											resource: vnode.state.screening,
											property: 'Venue',
										}),
										m(formGroup, {
											label: 'Film',
											isStatic: true,
											resource: vnode.state.screening,
											property: 'FilmTitle',
										}),
										m('.form-group.form-group-flex.form-group-static',[
											m('label', 'Details'),
											m('div', [
												m.trust(vnode.state.screening.current().Description)
											])
										]),
										// vnode.state.screening.current().Start > new Date()
										// 	? m(
										// 			'button.btn.btn-info',
										// 			{
										// 				type: 'button',
										// 				onclick: e => {
										// 					m.request({
										// 						method: 'POST',
										// 						url: `/api/screenings/ticket`,
										// 						data: vnode.state.screening.current(),
										// 						config: xhr => {
										// 							xhr.setRequestHeader(
										// 								'Authorization',
										// 								`Bearer ${localStorage.getItem('accessToken')}`
										// 							)
										// 						},
										// 					}).then(() => {
										// 						notifications.clear()
										// 						notifications.add({
										// 							title: 'Request sent',
										// 							message:
										// 								'Your request has been sent, we will contact you to confirm your attendance.',
										// 							type: 'success',
										// 						})
										// 						;(e.currentTarget || e.target).innerText = 'Request sent'
										// 						;(e.currentTarget || e.target).disabled = true
										// 						vnode.state.screening.current(false)
										// 					})
										// 				},
										// 			},
										// 			'Request ticket for screening'
										// 	  )
										// 	: null,
								  ])
								: m('p', 'Select a screening to view more details'),
						],
					}),
				]),
			]),
		]),
	]
}

export default screenings
