﻿import m from 'mithril'
import navbar from '../components/navbar'
import breadcrumb from './breadcrumb'
import notifications from '../components/notifications'
import icon from '../components/icon'

import variables from '../helpers/variables'

// Models
import Account from '../models/Account'

var layout = {}
layout.oninit = (vnode) => {}
layout.view = (vnode) => {
	return m(
		'.layout#layout',
		{
			className: vnode.attrs.className,
			ondragover,
			ondragenter: (e) => {
				if (isEventWithFiles(e)) $('.layout').addClass('is-dragover')
			},
			ondragleave,
			ondragend: (e) => {
				if (isEventWithFiles(e)) $('.layout').removeClass('is-dragover')
			},
		},
		[
			m(notifications),
			m(navbar, {
				color: ['dark'],
				toggler: true,
				brand: {
					title: variables.siteTitle,
					href: 'https://voting.bifa.film/',
				},
				oncreate: () => {
					$('.navbar-toggler').on('click', function (e) {
						$('.navbar-toggler').toggleClass('collapsed')
						$('#navbar').toggleClass('show')
					})
				},
				navitems: Account.user()
					? m('.collapse.navbar-collapse.justify-content-between#navbar', [
							m('ul.navbar-nav', []),
							m('ul.navbar-nav', [
								m('li.nav-item.d-lg-none', [
									m(
										'a.nav-link',
										{
											href: '/',
											oncreate: m.route.link,
											onclick: () => {
												$('.navbar-toggler').toggleClass('collapsed')
												$('#navbar').toggleClass('show')
											},
										},
										'Dashboard'
									),
								]),
								// m('li.nav-item.d-lg-none', [
								// 	m(
								// 		'a.nav-link',
								// 		{
								// 			href: '/membership',
								// 			oncreate: m.route.link,
								// 			onclick: () => {
								// 				$('.navbar-toggler').toggleClass('collapsed')
								// 				$('#navbar').toggleClass('show')
								// 			},
								// 		},
								// 		'Membership'
								// 	),
								// ]),
								m('li.nav-item.dropdown', [
									m(
										'a.nav-link.dropdown-toggle',
										{
											id: 'nav-Account',
											href: '#',
											'data-toggle': 'dropdown',
											'aria-haspopup': 'true',
										},
										`${Account.user().firstName} ${Account.user().lastName} `
									),
									m('.dropdown-menu.dropdown-menu-right', [
										m('h6.dropdown-header', Account.user().email),
										m(
											'a.dropdown-item',
											{
												oncreate: m.route.link,
												href: '/me',
											},
											[m('i.fa.fa-menu-item.fa-user-circle-o'), 'Account']
										),
										m(
											'a.dropdown-item',
											{
												oncreate: m.route.link,
												href: '/me/voting',
											},
											[m('i.fa.fa-menu-item.fa-tasks'), 'Voting Preferences']
										),
										Account.user().isUBTrainingLastYearDone
											? m(
													'a.dropdown-item',
													{
														oncreate: m.route.link,
														href: '/me/ubtraining',
													},
													[m('i.fa.fa-menu-item.fa-tasks'), 'Unconscious Bias Training']
											  )
											: null,
										Account.user().isSuperAdmin
											? m(
													'a.dropdown-item',
													{
														oncreate: m.route.link,
														href: '/admin',
													},
													[m('i.fa.fa-menu-item.fa-wrench'), 'Admin']
											  )
											: null,
										m(
											'button.dropdown-item',
											{
												type: 'button',
												onclick: () => Account.logout(),
											},
											[m('i.fa.fa-menu-item.fa-sign-out'), 'Log out']
										),
									]),
								]),
							]),
					  ])
					: null,
			}),
			Account.user() ? m(breadcrumb, vnode.attrs) : null,
			m(
				'.main',
				m('.row.no-gutters', [
					vnode.attrs.sidebar
						? m('.d-none.d-lg-flex.col-lg-2.sb', { style: 'background-color:#2028c4' }, [vnode.attrs.sidebar])
						: null,
					m(
						'.content-area',
						{
							id: 'content-area',
							className: vnode.attrs.sidebar ? 'col-md-12 col-lg-10' : 'col-sm-12',
						},
						[vnode.children]
					),
				])
			),
		]
	)
}

function isEventWithFiles(event) {
	var temp = (event.originalEvent || event).dataTransfer
	return temp && (temp = temp.types) && temp[0] === 'Files'
}

export default layout
