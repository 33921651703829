﻿import m from 'mithril'
import Account from '../models/Account'

import loading from '../components/loading'
import icon from '../components/icon'

var navigation = {}
navigation.view = vnode => {
	return [
		Account.user()
			? m('nav.collapse.sb-links', [
				m('.sb-toc-header.mt-3', 'Account'),
				m('.sb-toc-item', [
					m(
						'a.sb-toc-link',
						{
							href: `/me`,
							oncreate: m.route.link,
						},
						[m(icon, { iconName: 'id-card-o sb-icon' }), 'User details']
					),
				]),
				m('.sb-toc-item', [
					m(
						'a.sb-toc-link',
						{
							href: `/me/voting`,
							oncreate: m.route.link,
						},
						[m(icon, { iconName: 'id-card-o sb-icon' }), 'Voting Preferences']
					),
				]),
					m('.sb-toc-item', [
						m(
							'a.sb-toc-link',
							{
								href: `/me/changepassword`,
								oncreate: m.route.link,
							},
							[m(icon, { iconName: 'key sb-icon' }), 'Change password']
						),
					]),
					m('.sb-toc-item', [
						m(
							'a.sb-toc-link',
							{
								href: `/me/ubtraining`,
								oncreate: m.route.link,
							},
							[m(icon, { iconName: 'key sb-icon' }), 'Unconscious Bias Training']
						),
					]),
			  ])
			: m(loading),
	]
}

export default navigation
