﻿import m from 'mithril'
import loginForm from '../account/login'

const login = {}

login.view = vnode => {
	return m('.container-fluid', [m('.row', [m('.col-sm-8.col.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4', [m(loginForm)])])])
}

export default login
