﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { filter, map, groupBy, find } from 'lodash'
import dateFormat from 'dateformat'

import Film from '../models/Film'
import User from '../models/User'
import VotingCategory from '../models/VotingCategory'
// Components
import { card, button } from 'bootstrap-mithril'
import { actionbar } from '../components/actionbar'
import loading from '../components/loading'
import icon from '../components/icon'
import table from '../components/table'
import { categoryTag, entryTag } from '../components/categoryTag'
import tooltip from '../helpers/tooltip'

const viewing = {}
viewing.liveFilter = prop()
viewing.oninit = (vnode) => {
	if (vnode.attrs.user().isMember) {
		if (!User.subgroups()) {
			User.getSubgroups()
			User.getConfig()
		}
		if (!VotingCategory.list()) VotingCategory.getList()
		Film.getList()
	}
}
viewing.view = (vnode) => {
	return [
		m(actionbar, {
			breadcrumbs: [
				{
					text: 'All Films',
				},
			],
			buttons: [
				m(
					'button.btn.btn-refresh',
					{
						type: 'button',
						onclick: () => {
							Film.list(false)
							Film.getList()
						},
					},
					[m(icon, { iconName: 'refresh' }), 'Refresh']
				),
			],
		}),
		m('.container-fluid', [
			m('.row.main-content', [
				m('.col-md-12', [
					m(card, {
						bodyClasses: ['p-0'],
						header: [
							m('ul.nav.nav-tabs.card-header-tabs', { role: 'tablist' }, [
								User.subgroups()
									? map(User.subgroups(), (item) => {
											return m('li.nav-item', {}, [
												m(
													'a.nav-link',
													{
														href: `#films-subgroup-${item.id}`,
														role: 'tab',
														'aria-controls': `films-subgroup-${item.id}`,
														'aria-selected': parseInt(vnode.attrs.subgroup, 10) === item.id ? 'true' : 'false',
														'data-toggle': 'tab',
														className: parseInt(vnode.attrs.subgroup, 10) === item.id ? 'active' : '',
														onclick: () => {
															history.pushState(null, '', `/viewing/${item.id}`)
															viewing.liveFilter(false)
														},
													},
													[
														item.name,
														m(
															'span.badge.badge-info.ml-2',
															Film.list()
																? filter(
																		Film.list(),
																		(film) => filter(film.entries, (cat) => cat.subgroupId === item.id).length
																  ).length
																: null
														),
													]
												),
											])
									  })
									: null,
								m('li.nav-item', {}, [
									m(
										'a.nav-link',
										{
											href: '#films-assigned',
											role: 'tab',
											'aria-controls': 'films-assigned',
											'aria-selected': vnode.attrs.subgroup === 'assigned' ? 'true' : 'false',
											'data-toggle': 'tab',
											className: vnode.attrs.subgroup === 'assigned' ? 'active' : '',
											onclick: () => {
												history.pushState(null, '', `/viewing/assigned`)
												viewing.liveFilter(false)
											},
										},
										[
											'Assigned',
											m(
												'span.badge.badge-success.ml-2',
												Film.list() ? filter(Film.list(), (film) => film.isAssigned).length : null
											),
										]
									),
								]),
								m('li.nav-item', {}, [
									m(
										'a.nav-link',
										{
											href: '#films-new',
											role: 'tab',
											'aria-controls': 'films-new',
											'aria-selected': vnode.attrs.subgroup === 'new' ? 'true' : 'false',
											'data-toggle': 'tab',
											className: vnode.attrs.subgroup === 'new' ? 'active' : '',
											onclick: () => {
												history.pushState(null, '', `/viewing/new`)
												viewing.liveFilter(false)
											},
										},
										[
											'New',
											m(
												'span.badge.badge-warning.ml-2',
												Film.list() ? filter(Film.list(), (film) => film.isNew).length : null
											),
										]
									),
								]),
								m('li.nav-item', {}, [
									m(
										'a.nav-link',
										{
											href: '#films-all-shorts',
											role: 'tab',
											'aria-controls': 'films-all-shorts',
											'aria-selected': vnode.attrs.subgroup === 'all-shorts' ? 'true' : 'false',
											'data-toggle': 'tab',
											className: vnode.attrs.subgroup === 'all-shorts' ? 'active' : '',
											onclick: () => {
												history.pushState(null, '', `/viewing/all-shorts`)
												viewing.liveFilter(false)
											},
										},
										[
											'Short Films',
											Film.list()
												? m(
														'span.badge.badge-secondary.ml-2',
														filter(Film.list(), (film) => film.entries[0].votingCategoryShortName === 'Short').length
												  )
												: null,
										]
									),
								]),
								m('li.nav-item', {}, [
									m(
										'a.nav-link',
										{
											href: '#films-all-features',
											role: 'tab',
											'aria-controls': 'films-all-features',
											'aria-selected': vnode.attrs.subgroup === 'all-features' ? 'true' : 'false',
											'data-toggle': 'tab',
											className: vnode.attrs.subgroup === 'all-features' ? 'active' : '',
											onclick: () => {
												history.pushState(null, '', `/viewing/all-features`)
												viewing.liveFilter(false)
											},
										},
										[
											'Feature Films',
											Film.list()
												? m(
														'span.badge.badge-secondary.ml-2',
														filter(Film.list(), (film) => film.entries[0].votingCategoryShortName !== 'Short').length
												  )
												: null,
										]
									),
								]),
								m('li.nav-item', {}, [
									m(
										'a.nav-link',
										{
											href: '#films-all',
											role: 'tab',
											'aria-controls': 'films-all',
											'aria-selected': vnode.attrs.subgroup === 'all' ? 'true' : 'false',
											'data-toggle': 'tab',
											className: vnode.attrs.subgroup === 'all' ? 'active' : '',
											onclick: () => {
												history.pushState(null, '', `/viewing/all`)
												viewing.liveFilter(false)
											},
										},
										['All Films', Film.list() ? m('span.badge.badge-secondary.ml-2', Film.list().length) : null]
									),
								]),
							]),
						],
						body: [
							Film.list()
								? m('.tab-content', [
										User.subgroups()
											? map(User.subgroups(), (item) => {
													return m(
														'.tab-pane',
														{
															id: `films-subgroup-${item.id}`,
															role: 'tabpanel',
															className: parseInt(vnode.attrs.subgroup, 10) === item.id ? 'show active' : '',
														},
														[
															filmsTable(
																vnode,
																`films-subgroup-${item.id}`,
																(film) => {
																	return filter(film.entries, (cat) => cat.subgroupId === item.id).length
																},
																item.id,
																item.isSubTeams
															),
															item.isChair || vnode.attrs.user().isSuperAdmin
																? m(
																		'.btn.btn-info',
																		{
																			type: button,
																		},
																		'Download Viewings Feedback'
																  )
																: null,
														]
													)
											  })
											: null,
										m(
											'.tab-pane',
											{
												id: 'films-assigned',
												role: 'tabpanel',
												className: vnode.attrs.subgroup === 'assigned' ? 'show active' : '',
											},
											[
												filmsTable(vnode, 'films-assigned', (film) => {
													return film.isAssigned
												}),
											]
										),
										m(
											'.tab-pane',
											{
												id: 'films-new',
												role: 'tabpanel',
												className: vnode.attrs.subgroup === 'new' ? 'show active' : '',
											},
											[
												filmsTable(vnode, 'films-new', (film) => {
													return film.isNew
												}),
											]
										),
										m(
											'.tab-pane',
											{
												id: 'films-all-shorts',
												role: 'tabpanel',
												className: vnode.attrs.subgroup === 'all-shorts' ? 'show active' : '',
											},
											[
												filmsTable(vnode, 'films-all-shorts', (film) => {
													return film.entries[0].votingCategoryShortName === 'Short'
												}),
											]
										),
										m(
											'.tab-pane',
											{
												id: 'films-all-features',
												role: 'tabpanel',
												className: vnode.attrs.subgroup === 'all-features' ? 'show active' : '',
											},
											[
												filmsTable(vnode, 'films-all-features', (film) => {
													return film.entries[0].votingCategoryShortName !== 'Short'
												}),
											]
										),
										m(
											'.tab-pane',
											{
												id: 'films-all',
												role: 'tabpanel',
												className: vnode.attrs.subgroup === 'all' ? 'show active' : '',
											},
											[filmsTable(vnode, 'films-all', null)]
										),
								  ])
								: m(loading),
						],
					}),
				]),
			]),
		]),
	]
}

function filmsTable(vnode, key, tblFilter, subgroupId, subTeams) {
	const vcList = filter(VotingCategory.list(), (vc) => {
		return subgroupId === vc.subgroupId
	})
	return m(table, {
		key: key,
		resource: Film.list(),
		className: 'table-xs table-hover',
		rootUrl: `/films/`,
		doubleClick: false,
		filter: tblFilter,
		liveFilter: viewing.liveFilter(),
		filterRow: true,
		rowCallback: (item, _rowIx) => {
			item.className = item.isAssigned ? 'table-success' : item.isNew ? 'table-warning' : ''
		},
		columns: [
			{
				name: 'isNew',
				label: ' ',
				width: '33px',
				template: (value, item) => {
					if (item.isAssigned) return m(icon, { iconName: 'bookmark-o p-1 m-0', title: 'Assigned Film' })
					if (value)
						return m(icon, {
							iconName: 'star p-1 m-0',
							title: 'New film added: ' + dateFormat(item.createdAt, 'fullDate'),
						})
				},
			},
			{
				name: 'title',
				label: 'Title',
				width: '25%',
				link: true,
				filter: m('.input-group', [
					m('input.form-control.input-filter-Title', {
						type: 'text',
						key: 'input-filter-Title-' + key,
						value: viewing.liveFilter() ? viewing.liveFilter()['title'] : '',
						onkeyup: (e) => {
							const val = (e.currentTarget || e.target).value.toLowerCase()
							viewing.liveFilter({ title: val })
							e.redraw = true
						},
					}),
					m('.input-group-append', [
						m(
							'button.btn.btn-link',
							{
								type: 'button',
								onclick: () => {
									delete viewing.liveFilter()['title']
									$('.input-filter-Title').val('')
								},
							},
							m(icon, { iconName: 'close' })
						),
					]),
				]),
			},
			subTeams
				? {
						name: 'subTeam',
						label: 'Team',
						align: 'center',
						width: '70px',
						filter: m('.input-group', [
							m('input.form-control.input-filter-SubTeam', {
								type: 'text',
								key: 'input-filter-SubTeam-' + key,
								value: viewing.liveFilter() ? viewing.liveFilter()['subTeam'] : '',
								onkeyup: (e) => {
									const val = (e.currentTarget || e.target).value.toLowerCase()
									viewing.liveFilter({ subTeam: val })
									e.redraw = true
								},
							}),
							m('.input-group-append', [
								m(
									'button.btn.btn-link',
									{
										type: 'button',
										onclick: () => {
											delete viewing.liveFilter()['subTeam']
											$('.input-filter-SubTeam').val('')
										},
									},
									m(icon, { iconName: 'close' })
								),
							]),
						]),
				  }
				: null,
			{
				name: 'screener',
				label: 'Screener',
				width: '62px',
			},
			{
				name: 'runningTime',
				label: 'Duration',
				align: 'center',
				width: '60px',
			},
			{
				name: 'totalSeen',
				label: 'Total seen',
				align: 'center',
				width: '60px',
			},
			subgroupId
				? {
						name: 'entries',
						label: 'Subgroup Entries',
						template: (value, _item) => {
							return map(
								groupBy(value, (a) => a.entryId),
								(s) => {
									if (subgroupId === s[0].subgroupId)
										return [
											m(entryTag, {
												category: {
													name: s[0].votingCategory,
													subgroupName: s[0].subgroupName,
													shortName: s[0].votingCategoryShortName,
												},
												entries: s,
											}),
										]
								}
							)
						},
						filter:
							vcList.length > 1
								? m('.input-group', [
										m(
											'select.custom-select.input-filter-Entries_VotingCategoryId',
											{
												onchange: (e) => {
													const val = (e.currentTarget || e.target).value
													viewing.liveFilter({ entries_votingCategoryId: val })
													e.redraw = true
												},
											},
											[
												m('option', { value: '' }, 'Filter by category...'),
												map(vcList, (vc) => {
													return m('option', { value: vc.id }, vc.shortName)
												}),
											]
										),
										m('.input-group-append', [
											m(
												'button.btn.btn-link',
												{
													type: 'button',
													onclick: () => {
														delete viewing.liveFilter()['entries_votingCategoryId']
														$('.input-filter-Entries_VotingCategoryId').val('')
													},
												},
												m(icon, { iconName: 'close' })
											),
										]),
								  ])
								: null,
				  }
				: {
						name: 'entries',
						label: 'Entries',
						width: '20%',
						template: (value, _item) => {
							return map(
								groupBy(
									groupBy(value, (a) => a.entryId),
									(b) => b[0].votingCategory
								),
								(s) => {
									if (!s[0][0].subgroupIsHidden)
										return m(categoryTag, {
											category: {
												name: s[0][0].votingCategory,
												subgroupName: s[0][0].subgroupName,
												shortName: s[0][0].votingCategoryShortName,
											},
											entries: s,
										})
								}
							)
						},
						filter: m('.input-group', [
							m(
								'select.custom-select.input-filter-Entries_VotingCategoryId',
								{
									onchange: (e) => {
										const val = (e.currentTarget || e.target).value
										viewing.liveFilter({ entries_votingCategoryId: val })
										e.redraw = true
									},
								},
								[
									m('option', { value: '' }, 'Filter by category...'),
									map(VotingCategory.list(), (vc) => {
										return m('option', { value: vc.id }, vc.shortName)
									}),
								]
							),
							m('.input-group-append', [
								m(
									'button.btn.btn-link',
									{
										type: 'button',
										onclick: () => {
											delete viewing.liveFilter()['entries_votingCategoryId']
											$('.input-filter-Entries_VotingCategoryId').val('')
										},
									},
									m(icon, { iconName: 'close' })
								),
							]),
						]),
				  },
			subgroupId && User.config() && !User.config().isHideSubgroupStatus
				? {
						name: 'entries',
						label: 'Subgroup seen',
						align: 'center',
						template: (value, _item) => {
							return find(value, (s) => {
								return subgroupId === s.subgroupId
							}).subgroupSeen
						},
				  }
				: null,
			subgroupId && User.config() && !User.config().isHideSubgroupStatus
				? {
						name: 'entries',
						label: 'Subgroup status',
						align: 'center',
						template: (value, item) => {
							return map(
								groupBy(value, (a) => a.entryId),
								(s) => {
									if (subgroupId === s[0].subgroupId) {
										if (s[0].viewerStatus) {
											return m(
												'.badge.text-white.d-block',
												{
													className:
														s[0].viewerStatusId === 1
															? 'badge-danger'
															: s[0].viewerStatusId === 2
															? 'badge-warning'
															: s[0].viewerStatusId === 3
															? 'badge-success'
															: 'badge-primary',
												},
												s[0].viewerStatus
											)
										} else if (s[0].voterViewerStatus) {
											var totalStatus = s[0].subgroupNegative + s[0].subgroupNeutral + s[0].subgroupPositive
											return m('.progress', { style: 'height:15px;margin:3px 0' }, [
												m(
													'.progress-bar.bg-danger',
													Object.assign({}, tooltip(), {
														role: 'progressbar',
														style: `width:${(s[0].subgroupNegative / totalStatus) * 100}%`,
														title: `Not a contender`,
													}),
													s[0].subgroupNegative
												),
												m(
													'.progress-bar.bg-warning',
													Object.assign({}, tooltip(), {
														role: 'progressbar',
														style: `width:${(s[0].subgroupNeutral / totalStatus) * 100}%`,
														title: `More to see`,
													}),
													s[0].subgroupNeutral
												),
												m(
													'.progress-bar.bg-success',
													Object.assign({}, tooltip(), {
														role: 'progressbar',
														style: `width:${(s[0].subgroupPositive / totalStatus) * 100}%`,
														title: `All to See`,
													}),
													s[0].subgroupPositive
												),
											])
										} else if (item.isSeen) {
											return m('.progress', { style: 'height:15px;margin:3px 0' })
										}
									}
								}
							)
						},
				  }
				: null,
			subgroupId
				? {
						name: 'Entries',
						label: 'Your status',
						align: 'center',
						template: (value, item) => {
							return map(
								groupBy(value, (a) => a.entryId),
								(s) => {
									if (subgroupId === s[0].subgroupId && item.isSeen) {
										if (s[0].voterViewerStatus) {
											return m(
												'.badge.text-white.d-block',
												{
													className:
														s[0].voterViewerStatusId === 1
															? 'badge-danger'
															: s[0].voterViewerStatusId === 2
															? 'badge-warning'
															: 'badge-success',
												},
												s[0].voterViewerStatus
											)
										} else {
											return m('.badge.badge-light.d-block', 'Feedback required')
										}
									}
								}
							)
						},
				  }
				: null,
			{
				name: 'isConflicted',
				label: 'Conflict?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					if (value === null) return m(icon, { iconName: 'question text-muted p-1' })
					else if (value) return m(icon, { iconName: 'check-circle bg-danger p-1 rounded-circle text-white' })
				},
			},
			{
				name: 'isSeen',
				label: 'Seen?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					if (value === null) return m(icon, { iconName: 'question text-muted p-1' })
					else if (value) return m(icon, { iconName: 'check-circle bg-success p-1 rounded-circle text-white' })
				},
			},
		],
	})
}

export default viewing
