﻿import m from 'mithril'
import prop from 'mithril/stream'
import { cloneDeep, isEqual } from 'lodash'

import notifications from '../components/notifications'

var Film = {}

Film.list = prop()
Film.getList = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/films',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Film.list)
}

Film.listEntered = prop()
Film.getListEntered = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/films-entered',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Film.listEntered)
}

Film.current = prop()
Film.original = prop()
Film.clear = () => {
	Film.current(false)
	Film.original(false)
}
Film.get = (id) => {
	Film.current = prop()
	Film.original = prop()

	return m
		.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/${id}`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Film.current(clean)
			Film.original(cloneDeep(clean))
		})
		.catch((e) => {
			notifications.add({
				title: 'Film not found, redirecting back to film list',
				type: 'danger',
			})
			m.route.set('/viewing/all')
		})
}

Film.discard = () => {
	Film.current(cloneDeep(Film.original()))
}
Film.hasChanges = () => {
	return !isEqual(Film.current(), Film.original())
}

Film.save = () => {
	notifications.add({
		title: 'Saving film',
		type: 'saving',
	})
	return m
		.request({
			method: 'PUT',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/${Film.current().id}`,
			data: Film.current(),
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Film.current(clean)
			Film.original(cloneDeep(clean))
			notifications.clear()
			notifications.add({
				title: 'Film saved',
				type: 'success',
			})
		})
}

Film.toggleConflicted = (id) => {
	notifications.add({
		title: 'Changing conflicted status',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/${id}/toggle-conflicted`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((status) => {
			notifications.clear()
			return status
		})
}

Film.toggleSeen = (id) => {
	notifications.add({
		title: 'Changing seen status',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/${id}/toggle-seen`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((status) => {
			notifications.clear()
			return status
		})
}

Film.updateEntryViewerStatus = (id, viewerStatusId) => {
	notifications.add({
		title: 'Changing status',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/entries/${id}/update-viewerstatus/${viewerStatusId}`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((status) => {
			notifications.clear()
			return status
		})
}

Film.downloadEntryAttachment = (entryAttachmentId, name) => {
	return m
		.request({
			url: `https://bifa-api.azurewebsites.net/voting/films/entry-file?entryAttachmentId=${entryAttachmentId}`,
			method: 'GET',
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
				xhr.responseType = 'blob'
			},
			extract(val) {
				return val.response
			},
		})
		.then((res) => {
			var anchor = document.createElement('a')
			anchor.download = name
			anchor.href = window.URL.createObjectURL(res)
			anchor.click()
		})
		.catch((res) => {
			console.log(res)
		})
}

export default Film
