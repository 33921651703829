﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { find, map, remove, filter, groupBy, orderBy, forEach, times, sortBy, findIndex } from 'lodash'
import Choices from 'choices.js'
// import dragula from 'dragula'
import { DateTime } from 'luxon'

// Models
import User from '../models/User'
import Round from '../models/Round'
import Film from '../models/Film'

// Components
import { card } from 'bootstrap-mithril'
import input from '../components/input'
import table from '../components/table'
import loading from '../components/loading'
import icon from '../components/icon'
import { actionbar } from '../components/actionbar'
import notifications from '../components/notifications'

var page = {}
page.liveFilter = prop()
page.shortlists = prop([])
page.oninit = (vnode) => {
	Round.current(false)
	Round.categoryEntries(false)
	vnode.state.rjrvc = false
	vnode.state.roundOpen = false
	vnode.state.UBTrainingSuccess = true
	vnode.state.choices = []
	vnode.state.seen = 0
	Round.get(vnode.attrs.id).then(() => {
		User.get(vnode.attrs.user().id).then(() => {
			if (!page.shortlists()[vnode.attrs.rvc]) page.shortlists()[vnode.attrs.rvc] = []
			if (!User.subgroups()) User.getSubgroups(vnode.attrs.user().id)
			page.initState(vnode)
		})
	})
	Round.getCategoryEntries(vnode.attrs.id, vnode.attrs.rvc)
}

page.initState = (vnode) => {
	vnode.state.saving = false
	vnode.state.rjrvc = find(Round.current().roundJuror.roundJurorRoundVotingCategories, (rjrvc) => {
		return rjrvc.roundVotingCategory.id === parseInt(vnode.attrs.rvc)
	})
	vnode.state.categories = sortBy(
		map(Round.current().roundJuror.roundJurorRoundVotingCategories, (item) => {
			return {
				id: item.roundVotingCategory.id,
				name: item.roundVotingCategory.votingCategory.name,
				subgroup: User.subgroups()
					? find(User.subgroups(), ['id', item.roundVotingCategory.votingCategory.subgroupId])
					: false,
				sortOrder: item.roundVotingCategory.votingCategory.sortOrder,
			}
		}),
		['subgroup', 'sortOrder']
	)
	vnode.state.categoryIndex = findIndex(vnode.state.categories, ['id', vnode.state.rjrvc.roundVotingCategory.id])
	vnode.state.category = vnode.state.rjrvc.roundVotingCategory.votingCategory.name
	document.title = `${vnode.state.category} - ${Round.current().name} - Rounds - BIFA Voting`
	forEach(vnode.state.rjrvc.roundVotingCategory.rules, (item) => {
		if (item.ruleTypeId === 1) vnode.state.maxVotes = item.value
		else if (item.ruleTypeId === 2) vnode.state.minVotes = item.value
		else if (item.id === 12) vnode.state.mustMarkAll = item.description
		else if (item.id === 1019) {
			vnode.state.UBTrainingComplete = item.description
			vnode.state.UBTrainingSuccess = User.current().isUBTrainingDone
		}
	})
	page.UpdateSeen(vnode)
	vnode.state.roundOpen =
		DateTime.fromISO(Round.current().startDate) < DateTime.now() &&
		DateTime.fromISO(Round.current().endDate) >= DateTime.now() &&
		!Round.current().roundJuror.isComplete
}

page.UpdateSeen = (vnode) => {
	vnode.state.seen =
		vnode.state.rjrvc.voterSC.seenNotConflicted +
		(vnode.state.rjrvc.voterSC.includeConflictedSeen ? vnode.state.rjrvc.voterSC.conflicted : 0)
}

page.view = (vnode) => {
	return Round.current() && vnode.state.rjrvc
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: Round.current().name,
							href: `/rounds/${Round.current().number}`,
						},
						{
							text: vnode.state.category,
						},
					],
					buttons: vnode.state.roundOpen && [
						!vnode.state.rjrvc.isVotingForNone &&
							m(
								'button.btn',
								{
									className: vnode.state.rjrvc.isAbstaining ? 'btn-muted' : 'btn-warning',
									type: 'button',
									onclick: () => {
										if (vnode.state.rjrvc.isAbstaining)
											Round.unabstainCategory(vnode.attrs.rvc).then((res) => {
												vnode.state.rjrvc.isAbstaining = res
												m.redraw()
											})
										else
											Round.abstainCategory(vnode.attrs.rvc).then((res) => {
												vnode.state.rjrvc.isAbstaining = res
												m.redraw()
											})
									},
								},
								vnode.state.rjrvc.isAbstaining ? 'Remove category abstention' : 'Abstain from category'
							),
						Round.current().isVotingForNoneAllowed &&
							!vnode.state.rjrvc.isAbstaining &&
							vnode.state.UBTrainingSuccess &&
							vnode.state.rjrvc.voterSC.needed <= vnode.state.seen &&
							m(
								'button.btn',
								{
									className: vnode.state.rjrvc.isVotingForNone ? 'btn-muted' : 'btn-info',
									type: 'button',
									onclick: () => {
										Round.toggleVoteForNoneCategory(vnode.attrs.rvc).then((res) => {
											vnode.state.rjrvc.isVotingForNone = res
											if (res) page.oninit(vnode)
											m.redraw()
										})
									},
								},
								vnode.state.rjrvc.isVotingForNone ? 'Remove voting for none' : 'Vote for none'
							),
					],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col', [
							m(card, {
								bodyClasses: ['p-0'],
								header: m('ul.nav.nav-tabs.card-header-tabs', { role: 'tablist' }, [
									m('li.nav-item', {}, [
										m(
											'a.nav-link',
											{
												href: `#round-rules`,
												role: 'tab',
												'aria-controls': `round-rules`,
												'aria-selected': 'true',
												'data-toggle': 'tab',
												className: 'active',
											},
											'Rules & information'
										),
									]),
									m('li.nav-item', {}, [
										m(
											'a.nav-link',
											{
												href: `#round-entries`,
												role: 'tab',
												'aria-controls': `round-entries`,
												'aria-selected': 'false',
												'data-toggle': 'tab',
												className: '',
											},
											'Entries'
										),
									]),
									m('li.nav-item', {}, [
										m(
											'a.nav-link',
											{
												href: `#round-votes`,
												role: 'tab',
												'aria-controls': `round-votes`,
												'aria-selected': 'false',
												'data-toggle': 'tab',
												className:
													vnode.state.rjrvc.isAbstaining || vnode.state.rjrvc.isVotingForNone ? 'disabled' : '',
											},
											'Votes'
										),
									]),
								]),
								body: [
									m('.tab-content', [
										m(
											'.tab-pane.p-3',
											{
												id: 'round-rules',
												role: 'tabpanel',
												className: 'show active',
											},
											[
												m('.row', [
													m('col-md-7.col-lg-8', [
														m('h3', vnode.state.category),
														vnode.state.roundOpen &&
															m(
																'p',
																'Enter your votes by selecting an entry against the preference, 1 being your highest preference. Any additional documents for an entry will be listed below the film in the entry list tab, just click to view them. You can only give a vote for a film you have seen and are not conflicted on.'
															),
														m('ul', [
															map(vnode.state.rjrvc.roundVotingCategory.rules, (item) => {
																return m(
																	'li',
																	{
																		className:
																			vnode.state.UBTrainingComplete &&
																			item.id === 1019 &&
																			!User.current().isUBTrainingDone
																				? 'text-danger'
																				: '',
																	},
																	item.description
																)
															}),
														]),
													]),
													m('col-md-5.col-lg-4', [
														m('h3', 'Status'),
														m('table.table', [
															m('tr', [m('th', 'Entries'), m('td.text-right', vnode.state.rjrvc.voterSC.totalEntries)]),
															m('tr', [m('th', 'Films'), m('td.text-right', vnode.state.rjrvc.voterSC.films)]),
															m('tr', [
																m('th', 'Films required to vote'),
																m('td.text-right', vnode.state.rjrvc.voterSC.needed),
															]),
															m(
																'tr',
																{
																	className:
																		vnode.state.seen < vnode.state.rjrvc.voterSC.needed
																			? 'table-warning'
																			: 'table-success',
																},
																[m('th', 'Films seen'), m('td.text-right', vnode.state.rjrvc.voterSC.seenNotConflicted)]
															),
															m('tr', [
																m('th', 'Films conflicted'),
																m('td.text-right', vnode.state.rjrvc.voterSC.conflicted),
															]),
															m(
																'tr',
																{
																	className: vnode.state.rjrvc.isAbstaining
																		? 'table-warning'
																		: vnode.state.rjrvc.isVotingForNone
																		? 'table-primary'
																		: '',
																},
																[
																	m('th', 'Votes cast'),
																	m(
																		'td.text-right',
																		vnode.state.rjrvc.isAbstaining
																			? 'Abstaining'
																			: vnode.state.rjrvc.isVotingForNone
																			? 'None'
																			: vnode.state.rjrvc.votes.length
																	),
																]
															),
														]),
													]),
												]),
											]
										),
										m(
											'.tab-pane.p-3',
											{
												id: 'round-entries',
												role: 'tabpanel',
											},
											[entriesTable(vnode, 'entries-tbl')]
										),
										m(
											'.tab-pane.p-3',
											{
												id: 'round-votes',
												role: 'tabpanel',
											},
											[
												vnode.state.UBTrainingComplete &&
													!vnode.state.UBTrainingSuccess &&
													m('.alert.alert-danger', vnode.state.UBTrainingComplete),
												vnode.state.rjrvc.voterSC.needed > vnode.state.seen &&
													m(
														'.alert.alert-warning',
														`You have not yet seen enough films to vote in this category. You need to see at least ${
															vnode.state.rjrvc.voterSC.needed - vnode.state.seen
														} more films to be able to cast a vote.`
													),
												!vnode.state.roundOpen && m('.alert.alert-warning', 'Round is not open for voting.'),
												vnode.state.roundOpen &&
													vnode.state.rjrvc.voterSC.needed <= vnode.state.seen &&
													!(vnode.state.UBTrainingComplete && !User.current().isUBTrainingDone) &&
													m('.row', [
														m('.col-md-6', [
															m(card, {
																header: 'Shortlist',
																bodyClasses: ['p-0'],
																body: [
																	m('ul.list-group.list-group-flush.shortlist', [
																		map(page.shortlists()[vnode.attrs.rvc], (item) => {
																			return m('li.list-group-item', [
																				m('h6', item.filmTitle),
																				item.entryPeople && m('h6.text-muted', item.entryPeople),
																			])
																		}),
																	]),
																],
															}),
														]),
														vnode.state.roundOpen &&
															m('.col-md-6', [
																m(card, {
																	header: 'Votes',
																	bodyClasses: ['p-0'],
																	body: [
																		m(
																			'ul.list-group.list-group-flush.votes',
																			{
																				oncreate: (votes) => {
																					// let drake = dragula([votes.dom], {
																					// 	moves: function (el, container, handle) {
																					// 		return handle.classList.contains('handle')
																					// 	},
																					// })
																					// drake.on('drag', function () {
																					// 	vnode.state.draggingPage = true
																					// 	m.redraw()
																					// })
																					// drake.on('dragend', function () {
																					// 	vnode.state.draggingPage = false
																					// 	m.redraw()
																					// })
																					// drake.on('drop', () => {
																					// 	forEach(votes.dom.children, (el, ix) => {
																					// 		let existvote = find(vnode.state.rjrvc.votes, (vote) => {
																					// 			return vote.preference === ix + 1
																					// 		})
																					// 		el.children[0].children[0].innerText = ix + 1
																					// 		if (existvote) existvote.entryId = el.dataset.entryid
																					// 		else
																					// 			vnode.state.rjrvc.votes.push({
																					// 				entryId: el.dataset.entryid,
																					// 				preference: ix + 1,
																					// 			})
																					// 	})
																					// 	vnode.state.saving = true
																					// 	vnode.state.choices = []
																					// 	Round.vote(vnode.state.rjrvc.id, vnode.state.rjrvc.votes).then((v) => {
																					// 		vnode.state.saving = false
																					// 		vnode.state.rjrvc.votes = v
																					// 		m.redraw()
																					// 	})
																					// })
																				},
																			},
																			!vnode.state.saving
																				? [
																						times(vnode.state.maxVotes, (ix) => {
																							let existingVote = find(vnode.state.rjrvc.votes, (vote) => {
																								return vote.preference === ix + 1
																							})
																							return m(
																								'li.list-group-item.d-flex.p-1',
																								{
																									key: `pref_${vnode.state.rjrvc.id}_${ix}`,
																									'data-entryid': existingVote ? existingVote.entryId : 0,
																								},
																								[
																									m('.d-flex.align-items-center.p-2', [
																										m('span.badge.badge-info', ix + 1),
																									]),
																									Round.categoryEntries()
																										? m('select', {
																												id: `sel_${vnode.state.rjrvc.id}_${ix + 1}`,
																												key: `sel_${vnode.state.rjrvc.id}_${ix + 1}`,
																												className: 'choices__input is-hidden',
																												oncreate: (el) => {
																													const choices = new Choices(el.dom, {
																														searchEnabled: true,
																														shouldSort: true,
																														removeItemButton: true,
																														choices: [
																															{
																																value: null,
																																label: null,
																																customProperties: {
																																	title: null,
																																},
																															},
																														],
																														sorter: function (a, b) {
																															return b.customProperties.title < a.customProperties.title
																																? 1
																																: -1
																														},
																													})
																													vnode.state.choices.push(choices)
																													choices.setChoices(
																														getChoices(vnode.state.rjrvc.votes, ix),
																														'value',
																														'label',
																														false
																													)
																												},
																												onchange: (evt) => {
																													let existvote = find(vnode.state.rjrvc.votes, (vote) => {
																														return vote.preference === ix + 1
																													})
																													let val = evt.target.value === '' ? 0 : evt.detail.value
																													if (existvote) existvote.entryId = val
																													else
																														vnode.state.rjrvc.votes.push({
																															entryId: val,
																															preference: ix + 1,
																														})
																													vnode.state.saving = true
																													Round.vote(
																														vnode.state.rjrvc.id,
																														vnode.state.rjrvc.votes
																													).then((v) => {
																														vnode.state.saving = false
																														vnode.state.rjrvc.votes = v
																														m.redraw()
																													})
																												},
																										  })
																										: m(loading),
																									m('.d-flex.align-items-center.p-2', [
																										m(
																											'button.btn.btn-sm.border-0.mr-1.p-1',
																											{
																												type: 'button',
																												style: 'line-height:1',
																												className: ix === 0 ? 'btn-disabled' : 'btn-light',
																												onclick() {
																													let existvote = find(vnode.state.rjrvc.votes, (vote) => {
																														return vote.preference === ix + 1
																													})
																													let existvote2 = find(vnode.state.rjrvc.votes, (vote) => {
																														return vote.preference === ix
																													})
																													existvote.preference = ix
																													existvote2.preference = ix + 1
																													vnode.state.saving = true
																													Round.vote(
																														vnode.state.rjrvc.id,
																														vnode.state.rjrvc.votes
																													).then((v) => {
																														vnode.state.saving = false
																														vnode.state.rjrvc.votes = v
																														m.redraw()
																													})
																												},
																												disabled: ix === 0,
																											},
																											m('.span.icon-angle-up')
																										),
																										m(
																											'button.btn.btn-sm.border-0.p-1',
																											{
																												type: 'button',
																												style: 'line-height:1',
																												className:
																													ix === vnode.state.maxVotes - 1
																														? 'btn-disabled'
																														: 'btn-light',
																												onclick() {
																													let existvote = find(vnode.state.rjrvc.votes, (vote) => {
																														return vote.preference === ix + 1
																													})
																													let existvote2 = find(vnode.state.rjrvc.votes, (vote) => {
																														return vote.preference === ix + 2
																													})
																													existvote.preference = ix + 2
																													existvote2.preference = ix + 1
																													vnode.state.saving = true
																													Round.vote(
																														vnode.state.rjrvc.id,
																														vnode.state.rjrvc.votes
																													).then((v) => {
																														vnode.state.saving = false
																														vnode.state.rjrvc.votes = v
																														m.redraw()
																													})
																												},
																												disabled: ix === vnode.state.maxVotes - 1,
																											},
																											m('.span.icon-angle-down')
																										),
																									]),
																								]
																							)
																						}),
																				  ]
																				: m(
																						'div',
																						{
																							style: `height: ${vnode.state.maxVotes * 48}px`,
																						},
																						[m(loading)]
																				  )
																		),
																	],
																}),
															]),
													]),
											]
										),
									]),
								],
							}),
							m('.d-flex.w-100.justify-content-between.mt-4', [
								m(
									'a.btn',
									{
										className: vnode.state.categoryIndex === 0 ? 'btn-disabled' : 'btn-secondary',
										disabled: vnode.state.categoryIndex === 0,
										href:
											vnode.state.categoryIndex === 0
												? '#'
												: `/rounds/${vnode.attrs.id}/category/${
														vnode.state.categories[vnode.state.categoryIndex - 1].id
												  }`,
										oncreate: m.route.link,
									},
									vnode.state.categoryIndex === 0
										? null
										: [
												m(icon, { iconName: 'caret-left mr-2' }),
												vnode.state.categories[vnode.state.categoryIndex - 1].name,
										  ]
								),
								m(
									'a.btn',
									{
										className:
											vnode.state.categoryIndex === vnode.state.categories.length - 1 ? 'btn-success' : 'btn-secondary',
										href:
											vnode.state.categoryIndex === vnode.state.categories.length - 1
												? `/rounds/${vnode.attrs.id}/summary`
												: `/rounds/${vnode.attrs.id}/category/${
														vnode.state.categories[vnode.state.categoryIndex + 1].id
												  }`,
										oncreate: m.route.link,
									},
									[
										vnode.state.categoryIndex === vnode.state.categories.length - 1
											? 'View Votes Summary & Submission'
											: vnode.state.categories[vnode.state.categoryIndex + 1].name,
										m(icon, { iconName: 'caret-right ml-2' }),
									]
								),
							]),
						]),
					]),
				]),
		  ]
		: m(loading)
}

function entriesTable(vnode, key) {
	return m(table, {
		key: key,
		resource: Round.categoryEntries()
			? orderBy(
					map(
						groupBy(Round.categoryEntries(), (a) => a.entryId),
						(entry) => {
							return {
								entryId: entry[0].entryId,
								filmId: entry[0].filmId,
								filmTitle: entry[0].filmTitle,
								filmTitleNoThe: entry[0].filmTitleNoThe,
								filmTitleNoTheLower: entry[0].filmTitleNoThe.toLowerCase(),
								entryPeople: map(entry, (e) => {
									return [e.person, e.character ? ` (${e.character})` : null].join('')
								}).join(', '),
								viewerStatus: entry[0].viewerStatus,
								viewerStatusId: entry[0].viewerStatusId,
								voterViewerStatus: entry[0].voterViewerStatus,
								voterViewerStatusId: entry[0].voterViewerStatusId,
								isSeen: entry[0].isSeen,
								isConflicted: entry[0].isConflicted,
								isAssigned: entry[0].isAssigned,
							}
						}
					),
					['filmTitleNoTheLower', 'entryPeople']
			  )
			: null,
		className: 'table-xs',
		liveFilter: page.liveFilter(),
		filterRow: true,
		rowClick: false,
		rowCallback: (item, _rowIx) => {
			item.className = item.isConflicted ? 'table-danger' : item.isSeen === false ? 'table-secondary' : ''
		},
		columns: [
			{
				name: 'filmTitle',
				label: 'Film',
				width: '25%',
				template: (value, _item) => {
					return m('h6.mb-0', value)
				},
			},
			{
				name: 'entryPeople',
				label: 'Entry',
				width: '25%',
			},
			{
				name: 'isConflicted',
				label: 'Conflict?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					return m(
						'button.btn.btn-sm.btn-light',
						{
							type: 'button',
							id: `btn-conflicted-${item.entryId}`,
							key: `btn-conflicted-${item.entryId}`,
							onclick: () => {
								Film.toggleConflicted(item.filmId).then((x) => {
									let doneFilm = false
									forEach(Round.categoryEntries(), (z) => {
										if (z.filmId === item.filmId) {
											z.isConflicted = x
											if (!doneFilm) {
												if (z.isSeen === null) z.isSeen = false
												else if (z.isSeen === true && x) vnode.state.rjrvc.voterSC.seenNotConflicted--
											}
											doneFilm = true
										}
									})
									page.UpdateSeen(vnode)
									m.redraw()
								})
							},
						},
						value
							? m(icon, { iconName: 'check-circle bg-danger p-1 m-0 rounded-circle text-white' })
							: m(icon, { iconName: 'times-circle text-muted p-1 m-0' })
					)
				},
			},
			{
				name: 'isSeen',
				label: 'Seen?',
				align: 'center',
				width: '5%',
				template: (value, item) => {
					return m(
						'button.btn.btn-sm.btn-light',
						{
							type: 'button',
							id: `btn-seen-${item.entryId}`,
							key: `btn-seen-${item.entryId}`,
							onclick: () => {
								Film.toggleSeen(item.filmId).then((x) => {
									let doneFilm = false
									forEach(Round.categoryEntries(), (z) => {
										if (z.filmId === item.filmId) {
											if (!doneFilm) {
												if (x) vnode.state.rjrvc.voterSC.seenNotConflicted++
												else vnode.state.rjrvc.voterSC.seenNotConflicted--
											}
											z.isSeen = x
											doneFilm = true
										}
									})
									page.UpdateSeen(vnode)
									m.redraw()
								})
							},
						},
						value
							? m(icon, { iconName: 'check-circle bg-success p-1 m-0 rounded-circle text-white' })
							: m(icon, { iconName: 'times text-danger p-1 m-0' })
					)
				},
			},
			{
				name: 'voterViewerStatus',
				label: 'Your Status',
				width: '15%',
				template: (val, item) => {
					return item.isSeen && !item.isConflicted
						? m('h6', [
								m(
									'span.badge',
									{
										className:
											item.voterViewerStatusId === 1
												? 'badge-danger'
												: item.voterViewerStatusId === 2
												? 'badge-warning'
												: item.voterViewerStatusId === 3
												? 'badge-success'
												: null,
									},
									val
								),
						  ])
						: null
				},
			},
			{
				name: 'shortlist',
				label: 'Shortlist',
				align: 'center',
				width: '10%',
				template: (value, item) => {
					return item.isSeen && !item.isConflicted
						? m(
								'button.btn.btn-sm.btn-light',
								{
									type: 'button',
									id: `btn-shortlist-${item.entryId}`,
									key: `btn-shortlist-${item.entryId}`,
									onclick: () => {
										var exists = filter(page.shortlists()[vnode.attrs.rvc], (s) => {
											return s.entryId === item.entryId
										})
										if (exists.length)
											remove(page.shortlists()[vnode.attrs.rvc], (s) => {
												return s.entryId === item.entryId
											})
										else page.shortlists()[vnode.attrs.rvc].push(item)
										m.redraw()
									},
								},
								find(page.shortlists()[vnode.attrs.rvc], (s) => {
									return s.entryId === item.entryId
								})
									? m(icon, { iconName: 'star bg-success p-1 m-0 rounded-circle text-white' })
									: m(icon, { iconName: 'star-o text-muted p-1 m-0' })
						  )
						: null
				},
			},
		],
	})
}

function getChoices(votes, ix) {
	return map(
		groupBy(Round.categoryEntries(), (a) => a.entryId),
		(entry) => {
			let entryPeople = map(entry, (e) => {
				return [e.person, e.character ? ` (${e.character})` : null].join('')
			}).join(', ')
			return !entry[0].isConflicted && entry[0].isSeen
				? {
						value: entry[0].entryId,
						label: entry[0].filmTitle + (entryPeople ? ' - ' + entryPeople : ''),
						customProperties: {
							title: entry[0].filmTitleNoThe,
						},
						selected: find(votes, (vote) => {
							return vote.entryId === entry[0].entryId && vote.preference === ix + 1
						})
							? true
							: false,
						disabled: find(votes, (vote) => {
							return vote.entryId === entry[0].entryId && vote.preference !== ix + 1
						})
							? true
							: false,
				  }
				: false
		}
	)
}

export default page
