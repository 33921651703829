function tooltip(dataPlacement) {
	return {
		oncreate: vnode => {
			$(vnode.dom).tooltip({
				trigger: 'hover focus',
				animation: false,
			})
		},
		onremove: vnode => {
			$(vnode.dom).tooltip('dispose')
		},
		'data-toggle': 'tooltip',
		'data-placement': dataPlacement || 'top',
		'data-html': 'true',
		'data-container': 'body',
	}
}
export default tooltip
