﻿import m from 'mithril'
import prop from 'mithril/stream'
import { cloneDeep, isEqual, groupBy } from 'lodash'

import notifications from '../components/notifications'

var Round = {}

Round.list = prop()
Round.getList = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/rounds',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Round.list)
}

Round.films = prop()
Round.getFilms = (id) => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${id}/films`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Round.films)
}

Round.entries = prop()
Round.getEntries = (id) => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${id}/entries`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Round.entries)
}

Round.categoryEntries = prop()
Round.getCategoryEntries = (id, rvc) => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${id}/category/${rvc}/entries`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(Round.categoryEntries)
}

Round.current = prop()
Round.original = prop()
Round.clear = () => {
	Round.current(false)
	Round.original(false)
}
Round.get = (id) => {
	Round.current = prop()
	Round.original = prop()

	return m
		.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${id}`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Round.current(clean)
			Round.original(cloneDeep(clean))
		})
		.catch((e) => {
			notifications.add({
				title: 'Round not found, redirecting back to dashboard',
				type: 'danger',
			})
			m.route.set('/')
		})
}

Round.discard = () => {
	Round.current(cloneDeep(Round.original()))
}
Round.hasChanges = () => {
	return !isEqual(Round.current(), Round.original())
}

Round.submit = () => {
	notifications.add({
		title: 'Submitting round',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/submit`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Round.current().RoundJuror = clean
			notifications.clear()
			notifications.add({
				title: 'Round saved',
				type: 'success',
			})
		})
}

Round.abstain = () => {
	notifications.add({
		title: 'Abstaining from round',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/abstain`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Round.current(clean)
			Round.original(cloneDeep(clean))
			notifications.clear()
			notifications.add({
				title: 'Abstained from round',
				type: 'success',
			})
		})
}

Round.unabstain = () => {
	notifications.add({
		title: 'Removing abstention from round',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/unabstain`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			Round.current(clean)
			Round.original(cloneDeep(clean))
			notifications.clear()
			notifications.add({
				title: 'Removed abstention from round',
				type: 'success',
			})
		})
}

Round.abstainCategory = (id) => {
	notifications.add({
		title: 'Abstaining from round category',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/category/${id}/abstain`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			notifications.clear()
			return clean
		})
}
Round.unabstainCategory = (id) => {
	notifications.add({
		title: 'Removing abstention from round category',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/category/${id}/unabstain`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			notifications.clear()
			return clean
		})
}
Round.toggleVoteForNoneCategory = (id) => {
	notifications.add({
		title: 'Saving voting for none',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/${Round.current().id}/category/${id}/votefornone`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			notifications.clear()
			return clean
		})
}
Round.vote = (rjrvc, votes) => {
	notifications.add({
		title: 'Saving votes',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/rounds/vote/${rjrvc}`,
			data: votes,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then((clean) => {
			notifications.clear()
			return clean
		})
}

export default Round
