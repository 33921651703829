﻿import m from 'mithril'
import prop from 'mithril/stream'

var VotingCategory = {}

VotingCategory.list = prop()
VotingCategory.getList = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/categories',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(VotingCategory.list)
}

export default VotingCategory
