﻿// Libraries
import m from 'mithril'
import prop from 'mithril/stream'
import { forEach, isEqual } from 'lodash'

// Models
import User from '../models/User'
import Account from '../models/Account'

// Components
import { card } from 'bootstrap-mithril'
import icon from '../components/icon'
import { actionbar } from '../components/actionbar'
import loading from '../components/loading'
import formGroup from '../components/formGroup'
import saveBtn from '../components/saveBtn'
import notifications from '../components/notifications'

var pageUserChangePassword = {}

pageUserChangePassword.oninit = (vnode) => {
	if (!User.current()) User.get(vnode.attrs.user().id)
	vnode.state.changePassword = {
		current: prop({
			oldpassword: null,
			newpassword: null,
			confirmpassword: null,
		}),
		original: prop({
			oldpassword: null,
			newpassword: null,
			confirmpassword: null,
		}),
		hasChanges: () => {
			return !isEqual(vnode.state.changePassword.current(), vnode.state.changePassword.original())
		},
		save: () =>
			Account.changePassword(vnode.state.changePassword.current()).then(
				(data) => {
					notifications.add({
						title: 'Password updated',
						message: 'Good work!',
						type: 'success',
					})
					vnode.state.changePassword.current().newpassword = null
					vnode.state.changePassword.current().oldpassword = null
					vnode.state.changePassword.current().confirmpassword = null
					m.redraw()
					m.route.set('/')
				},
				(data) => {
					notifications.add({
						title: 'Password update failed',
						message: data.ModelState['model.NewPassword']
							? 'New password must be 8 characters minimum, with at least 1 uppercase, lowercase and digit'
							: '',
						type: 'danger',
						timeToDisplay: 30000,
					})
				}
			),
	}
}

pageUserChangePassword.view = (vnode) => {
	return User.current()
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'Account',
						},
						{
							text: 'User details',
						},
					],
					buttons: [m(saveBtn, { resource: vnode.state.changePassword })],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col-sm-6', [
							m(card, {
								title: 'Change Password',
								icon: 'user-circle-o',
								body: m('.card-block-flush', [
									m(
										'p.text-danger.font-weight-bold',
										`${
											vnode.attrs.expired
												? 'Your password has expired, please enter it again and create a new one. '
												: ''
										}Passwords must be 8 characters minimum, including at least 1 uppercase, lowercase and digit`
									),
									m(formGroup, {
										label: 'Current Password',
										type: 'textfield',
										subtype: 'password',
										resource: vnode.state.changePassword,
										property: 'oldpassword',
										required: true,
										pattern: '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
										title: '8 characters minimum, at least 1 uppercase, lowercase and digit',
									}),
									m(formGroup, {
										label: 'New Password',
										type: 'textfield',
										subtype: 'password',
										resource: vnode.state.changePassword,
										property: 'newpassword',
										required: true,
										pattern: '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
										title: '8 characters minimum, at least 1 uppercase, lowercase and digit',
									}),
									m(formGroup, {
										label: 'Confirm New Password',
										type: 'textfield',
										subtype: 'password',
										resource: vnode.state.changePassword,
										property: 'confirmpassword',
										required: true,
										pattern: '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
										title: '8 characters minimum, at least 1 uppercase, lowercase and digit',
									}),
								]),
							}),
						]),
					]),
				]),
		  ]
		: m(loading)
}

export default pageUserChangePassword
