﻿import m from 'mithril'
import prop from 'mithril/stream'
import { map, forEach } from 'lodash'
import velocity from 'velocity-animate'
import Flatpickr from 'flatpickr'
import Choices from 'choices.js'
import icon from '../components/icon'
import assignValue from '../helpers/assignValue'
import getResourceObject from '../helpers/getResourceObject'
import { uploadHelper } from '../helpers/uploadHelper'
import notifications from '../components/notifications'

function onBeforeUpdate(vnode) {
	var r = getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)
	if (r) {
		var stateChanges =
			r[vnode.attrs.property] !== vnode.state.initialValue && !(vnode.state.initialValue === null && r[vnode.attrs.property] === '')
		if (vnode.attrs.resource.hasChanges) {
			if (stateChanges && !vnode.attrs.resource.hasChanges()) {
				vnode.state.isSaved(true)
				stateChanges = false
				vnode.state.initialValue = r[vnode.attrs.property]
			}
		}
		vnode.state.hasChanges(stateChanges)
	}
}

function onUpdate(vnode) {
	if (vnode.state.isSaved()) {
		velocity(vnode.dom, { backgroundColor: '#D7FFF1' }, { duration: 'fast' }).then(() => {
			velocity(vnode.dom, { backgroundColor: '#fff' }, { duration: 1000 }).then(() => {
				vnode.dom.style.backgroundColor = ''
			})
			vnode.state.isSaved(false)
		})
	}
}

var input = {}
input.onbeforeupdate = onBeforeUpdate
input.onupdate = onUpdate
input.oninit = vnode => {
	vnode.state.hasChanges = prop(false)
	vnode.state.isSaved = prop(false)
	vnode.state.initialValue = getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property]
	vnode.state.id = vnode.attrs.id || `i_${vnode.attrs.objectPath || ''}_${vnode.attrs.property}`
	vnode.state.uploading = prop(false)
}
input.view = vnode => {
	if (vnode.attrs.type === 'textfield' || vnode.attrs.type === 'hyperlink' || vnode.attrs.type === 'number') {
		return m('input.form-control', {
			id: vnode.state.id,
			className: vnode.state.hasChanges() ? 'has-changes' : '',
			required: vnode.attrs.required,
			type: vnode.attrs.subtype || (vnode.attrs.type === 'number' ? 'number' : 'text'),
			onkeyup: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
			onchange: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
			value: getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property],
		})
	} else if (vnode.attrs.type === 'textarea') {
		return m('.textarea', [
			m('textarea.form-control', {
				id: vnode.state.id,
				className: vnode.state.hasChanges() ? 'has-changes' : '',
				required: vnode.attrs.required,
				rows: vnode.attrs.rows,
				onkeyup: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
				onchange: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
				value: getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property],
			}),
		])
	} else if (vnode.attrs.type === 'checkbox') {
		return m('input.custom-control-input', {
			id: vnode.state.id,
			onclick: e => {
				e.redraw = false
			},
			type: 'checkbox',
			className: vnode.state.hasChanges() ? 'has-changes' : '',
			required: vnode.attrs.required,
			onkeyup: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath, vnode.attrs.isList),
			onchange: e => {
				assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath, vnode.attrs.isList)(e)
				if (e.currentTarget.checked === true) {
					if (vnode.attrs.onchange)
						vnode.attrs.onchange(getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList))
				}
			},
			checked:
				getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] === 'true' ||
				getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] === true,
		})
	} else if (vnode.attrs.type === 'datetime') {
		return m('input.form-control', {
			type: 'text',
			className: vnode.state.hasChanges() ? 'has-changes' : '',
			oncreate: input => {
				vnode.state.picker = new Flatpickr(input.dom, {
					defaultDate: Date.parse(
						getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property]
					),
					enableTime: vnode.attrs.time || false,
					altInput: true,
					altFormat: 'd/m/Y',
					allowInput: true,
					onChange: (selectedDates, dateStr, instance) => {
						instance.element.className = 'form-control flatpickr-input' + (dateStr !== vnode.state.initialValue ? ' has-changes' : '')
						getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] = dateStr
						m.redraw()
					},
				})
			},
			onremove: () => {
				vnode.state.picker.destroy()
			},
		})
	} else if (vnode.attrs.type === 'contenteditable') {
		return m(
			'div.content-editable',
			{
				id: vnode.state.id,
				className: vnode.state.hasChanges() ? 'has-changes' : '',
				onkeyup: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
				onchange: assignValue(vnode.attrs.resource, vnode.attrs.property, vnode.attrs.objectPath),
				contenteditable: 'true',
			},
			m.trust(getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property])
		)
	} else if (vnode.attrs.type === 'select') {
		return [
			m('select', {
				id: vnode.state.id,
				multiple: vnode.attrs.subtype === 'multiple',
				className: vnode.state.hasChanges() ? 'choices__input is-hidden has-changes' : 'choices__input is-hidden',
				oncreate: el => {
					const choices = new Choices(el.dom, {
						search: vnode.attrs.search || true,
						shouldSort: vnode.attrs.shouldSort || false,
						removeItemButton: true,
						choices: [{ value: null, label: null }],
					})
					choices.setChoices(vnode.attrs.choices, 'value', 'label', false)
					if (vnode.attrs.showDrawer) {
						choices.passedElement.addEventListener('showDropdown', function(event) {
							var drawers = document.getElementsByClassName('drawer')
							for (var i = 0, l = drawers.length; i < l; i++) {
								drawers[i].classList.add('choices-open')
							}
						})
						choices.passedElement.addEventListener('hideDropdown', function(event) {
							var drawers = document.getElementsByClassName('drawer')
							for (var i = 0, l = drawers.length; i < l; i++) {
								drawers[i].classList.remove('choices-open')
							}
						})
					}
				},
				onchange: evt => {
					if (vnode.attrs.subtype === 'multiple') {
						var curArr = getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property].split(
							','
						)
						if (includes(curArr, evt.detail.value)) {
							pull(curArr, evt.detail.value)
						} else {
							curArr.push(evt.detail.value)
						}
						pull(curArr, '')
						getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] = curArr.join(',')
					} else {
						getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] = vnode.attrs
							.isPropertyArray
							? [evt.detail.value]
							: evt.detail.value
					}
					if (vnode.attrs.change) {
						vnode.attrs.change()
					}
				},
				onbeforeremove: el => {
					if (vnode.attrs.showDrawer) {
						var drawers = document.getElementsByClassName('drawer')
						for (var i = 0, l = drawers.length; i < l; i++) {
							drawers[i].classList.remove('choices-open')
						}
					}
				},
			}),
		]
	} else if (vnode.attrs.type === 'upload') {
		return m('.image-upload-group', { className: vnode.state.hasChanges() ? 'has-changes' : '' }, [
			m('input.form-control.image-upload', {
				type: 'file',
				style: { visibility: 'hidden', left: '-9999px', position: 'absolute' },
				oncreate: hiddenFileInput => {
					vnode.state.hiddenFileInput = hiddenFileInput.dom
				},
				onchange: e => {
					vnode.state.uploading(true)
					var files = (e.dataTransfer || e.target).files
					uploadHelper(files, vnode.attrs.uploadTarget, vnode.attrs.model, vnode.attrs.id).then(data => {
						notifications.clear()
						if (data) {
							notifications.add({ type: 'success', title: 'Upload Complete', message: `Uploaded new ${vnode.attrs.label}` })
							getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property] =
								data[vnode.attrs.property]
						}
						vnode.state.uploading(false)
						e.target.value = ''
					})
				},
			}),
			m('.d-flex.flex-column', [
				getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property].length
					? m('img', {
							src: `${
								getResourceObject(vnode.attrs.resource, vnode.attrs.objectPath, vnode.attrs.isList)[vnode.attrs.property]
							}?fm=auto&w=240`,
					  })
					: null,
				m(
					'button.btn.btn-info.btn-upload',
					{
						type: 'button',
						onclick: () => {
							vnode.state.hiddenFileInput.click()
						},
						disabled: vnode.state.uploading(),
					},
					[
						m(icon, { iconName: 'file-upload' }),
						vnode.state.uploading() ? 'Uploading...' : vnode.attrs.buttonText ? vnode.attrs.buttonText : 'Upload a file',
					]
				),
			]),
		])
	}
}

export default input
