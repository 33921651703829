﻿import m from 'mithril'
import tooltip from '../helpers/tooltip'

var icon = {}

icon.view = vnode => {
	const opts = { className: 'icon-' + vnode.attrs.iconName, title: vnode.attrs.title }
	return m('i', vnode.attrs.title ? Object.assign({}, tooltip(), opts) : opts)
}
export default icon
