﻿import m from 'mithril'
import prop from 'mithril/stream'
import Account from '../models/Account'
import notifications from '../components/notifications'
import loading from '../components/loading'
import { card, formgroup } from 'bootstrap-mithril'
import setValue from '../helpers/setValue'

const form = {}

form.oninit = (vnode) => {
	vnode.state.loading = prop(false)
	vnode.state.dto = {
		usernameOrEmail: '',
		domain: process.env.APP_DOMAIN,
	}

	vnode.state.submitForm = (e) => {
		e.preventDefault()
		vnode.state.loading(true)
		Account.forgotPassword(vnode.state.dto)
			.then(() => {
				vnode.state.loading(false)
				m.route.set('forgottenpasswordsuccess')
			})
			.catch((e) => {
				vnode.state.loading(false)
				return e
					? notifications.add({
							type: 'Error',
							title: 'Forgotten Password Issue',
							message: e.error_description,
					  })
					: null
			})
	}
}

form.view = (vnode) => {
	return m(card, {
		classes: ['login-form', 'mt-5'],
		header: 'Forgotten Password',
		body: m(
			'form',
			{
				onsubmit: vnode.state.submitForm,
			},
			[
				m(formgroup, {
					label: 'Email',
					input: m('input.form-control', {
						id: 'email',
						type: 'email',
						onchange: setValue(vnode.state.dto, 'usernameOrEmail'),
						value: vnode.state.dto.usernameOrEmail,
						required: 'required',
					}),
					inputId: 'email',
				}),
				vnode.state.loading()
					? m(loading)
					: m(
							'button.btn.btn-primary',
							{
								type: 'submit',
							},
							['Submit']
					  ),
			]
		),
	})
}

export default form
