﻿import m from 'mithril'
import resetForm from '../account/reset'

var pageResetPassword = {}

pageResetPassword.view = vnode => {
	return m('.container-fluid', [m('.row', [m('.col-sm-8.col.offset-sm-2.col-md-6.offset-md-3.col-lg-4.offset-lg-4', [m(resetForm, vnode.attrs)])])])
}

export default pageResetPassword
