﻿import m from 'mithril'
import breadcrumb from './breadcrumb'
import icon from './icon'

var actionbar = {}
actionbar.view = vnode => {
	return m('.action-bar.p-0.col-md-12.col-lg-10', [
		vnode.attrs.breadcrumbs ? m(breadcrumb, { breadcrumbs: vnode.attrs.breadcrumbs }) : null,
		vnode.attrs.buttons ? m('.btns', vnode.attrs.buttons) : null,
	])
}
var actionbarBtn = {}
actionbarBtn.view = vnode => {
	return vnode.attrs.href
		? m(
				'a.btn.btn-sm.btn-info',
				{
					href: vnode.attrs.href,
					oncreate: m.route.link,
				},
				[vnode.attrs.icon ? m(icon, { iconName: vnode.attrs.icon }) : null, m('span.btn-text', vnode.attrs.label)]
		  )
		: m(
				'button.btn.btn-sm.btn-info',
				{
					type: 'button',
					onclick: vnode.attrs.onclick,
				},
				[vnode.attrs.icon ? m(icon, { iconName: vnode.attrs.icon }) : null, m('span.btn-text', vnode.attrs.label)]
		  )
}

export { actionbar, actionbarBtn }
