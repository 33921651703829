import m from "mithril"
import {remove,uniqWith,isEqual} from "lodash"
import velocity from "velocity-animate"
import swipe from "../helpers/touchSwipe"

var notifications = {}

notifications.list = []

notifications.clear = () => {
    notifications.list = []
    m.redraw()
}
notifications.add = (n) => {
    notifications.list.push(n)
    notifications.list = uniqWith(notifications.list, isEqual)
    m.redraw()
}
notifications.remove = () => {
    notifications.list.shift()
    m.redraw()
}

notifications.view = function () {
    return m(".notifications", [
        notifications.list.map(function (value) {
           return m(toast, {
                notification: value
            })
        })
    ])
}

var toast = {}
toast.oninit = function (vnode) {
    
    vnode.state.timeToDisplay = vnode.attrs.notification.timeToDisplay || 4000
    vnode.state.hovering = false

    vnode.state.progress = function (element) {
        vnode.state.bar = element
        if (!vnode.state.hovering) {
            velocity(element, {
                width: "0"
            }, {
                progress: function (e, c, r) {
                    vnode.state.timeToDisplay = r
                },
                duration: vnode.state.timeToDisplay
            }).then(function () {
                if (!vnode.state.hovering) {
                    velocity(element.parentNode, {
                        opacity: 0
                    }, 300).then(function () {
                        if (!vnode.state.timeToDisplay) {
                            notifications.remove()
                            m.redraw()
                        }
                    })
                }
            })
        }
    }

    vnode.state.pause = function () {
        //velocity(vnode.state.bar, "stop")
        //velocity(vnode.state.bar.parentNode, "stop")
        vnode.state.hovering = true
    }

    vnode.state.unpause = function () {
        vnode.state.hovering = false
        vnode.state.progress(vnode.state.bar)
    }

    vnode.state.onSwipe = function (element, overallMovement) {
        var toastOpacity = 200 / overallMovement / 25

        element.style.opacity = toastOpacity
    }
}

toast.view = function (vnode) {
    return m(".toast", {
        oncreate: function (toast) {
            swipe(toast.dom, {
                onchange: vnode.state.onSwipe
            })
        },
        onmouseover: vnode.state.pause,
        onmouseleave: vnode.state.unpause,
        class : vnode.attrs.notification.type  || "signal"
    }, [
      m(".type"),

      m(".content.body", [
        m("h5", vnode.attrs.notification.title),
        m("p", vnode.attrs.notification.message)
      ]),


      m(".progress.bar", {
          oncreate: (progress) =>  vnode.state.progress(progress.dom)
      })
    ])
}

export default notifications