﻿import {forEach} from "lodash"

function getResourceObject(resource, objectPath) {
    var r = resource.current ? resource.current() : resource.list().items
    if (objectPath && r) {
        var paths = objectPath.split('.');
        forEach(objectPath.split('.'), (path) => {
            r = r[path]
        })
    }
    return r
}

export default getResourceObject