﻿import m from 'mithril'
import icon from './icon'

var saveBtn = {}
saveBtn.view = (vnode) => {
	return m(
		'button.btn.btn-save',
		{
			type: 'button',
			className: vnode.attrs.resource.hasChanges() ? 'btn-success' : null,
			onclick: () =>
				vnode.attrs.resource.current().id === 0
					? vnode.attrs.resource.create(vnode.attrs.resourceParam)
					: vnode.attrs.resource.save(vnode.attrs.resourceParam),
			disabled: !vnode.attrs.resource.hasChanges(),
		},
		[
			m(icon, { iconName: vnode.attrs.resource.hasChanges() ? 'exclamation-circle' : 'check-circle' }),
			!vnode.attrs.resource.hasChanges() ? 'Saved' : 'Save changes',
		]
	)
}
export default saveBtn
