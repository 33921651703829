﻿import m from 'mithril'
import { map } from 'lodash'
import tooltip from '../helpers/tooltip'

var categoryTag = {}

categoryTag.view = (vnode) => {
	const entries = map(vnode.attrs.entries, (e) => {
		return map(e, (p) => {
			return p.person
		}).join(', ')
	}).join('<br><i class="icon icon-user"></i> ')
	return m(
		'.badge.badge-secondary.mr-1',
		Object.assign({}, tooltip(), {
			title: `${vnode.attrs.category.name}<br>(Subgroup: ${vnode.attrs.category.subgroupName})<br>${
				entries ? '<i class="icon icon-user"></i> ' : ''
			}${entries}`,
		}),
		vnode.attrs.category.shortName + (vnode.attrs.entries.length > 1 ? ` (${vnode.attrs.entries.length})` : '')
	)
}

var entryTag = {}

entryTag.view = (vnode) => {
	const entries = map(vnode.attrs.entries, (e) => {
		return [e.person, e.character ? ` (${e.character})` : null].join('')
	}).join(', ')
	return m('.d-block', [
		m(
			'span',
			{
				title: `${vnode.attrs.category.name}<br>${entries ? '<i class="icon icon-user"></i> ' : ''}${entries}`,
				oncreate: (vnode) => {
					$(vnode.dom).tooltip({
						trigger: 'hover',
						animation: false,
					})
				},
				onremove: (vnode) => {
					$(vnode.dom).tooltip('dispose')
				},
				'data-toggle': 'tooltip',
				'data-placement': 'top',
				'data-html': 'true',
			},
			vnode.attrs.category.shortName
		),
	])
}

export { categoryTag, entryTag }
