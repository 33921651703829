﻿import m from 'mithril'
import { times } from 'lodash'

import icon from './icon'

var pagination = {}
pagination.view = vnode => {
	return m('ul.pagination.pagination-sm', [
		m(
			'li.page-item',
			{
				disabled: vnode.attrs.resource.list().page === 1 ? true : false,
				className: vnode.attrs.resource.list().page === 1 ? 'disabled' : '',
			},
			[
				m(
					"button.page-link[xlink:aria-label='Previous']",
					{
						type: 'button',
						onclick: () => {
							vnode.attrs.resource.getList({
								page: vnode.attrs.resource.list().page - 1,
								pageSize: vnode.attrs.resource.list().pageSize,
								selectedItemId: vnode.attrs.selectedItemId,
								sort: vnode.attrs.sort,
							})
						},
					},
					[m(icon, { iconName: 'angle-left' }), m('span.sr-only', 'Previous')]
				),
			]
		),

		vnode.attrs.resource.list().pageCount > 0
			? times(parseInt(vnode.attrs.resource.list().pageCount), num => {
					return num < 3 || num > vnode.attrs.resource.list().pageCount - 4
						? m(
								'li.page-item',
								{
									key: num,
									className: num + 1 === vnode.attrs.resource.list().page ? 'active' : '',
								},
								m(
									'button.page-link',
									{
										type: 'button',
										onclick: () => {
											vnode.attrs.resource.getList({
												page: num + 1,
												pageSize: vnode.attrs.resource.list().pageSize,
												selectedItemId: vnode.attrs.selectedItemId,
												sort: vnode.attrs.sort,
											})
										},
									},
									num + 1
								)
						  )
						: vnode.attrs.resource.list().pageCount > 6 && num === 3
							? m(
									'li.page-item.disabled',
									{
										key: -1,
									},
									m('span.page-link', '...')
							  )
							: null
			  })
			: '',

		m(
			'li.page-item',
			{
				disabled: vnode.attrs.resource.list().page === vnode.attrs.resource.list().pageCount ? true : false,
				className: vnode.attrs.resource.list().page === vnode.attrs.resource.list().pageCount ? 'disabled' : '',
			},
			[
				m(
					"button.page-link[xlink:aria-label='Next']",
					{
						type: 'button',
						onclick: () => {
							vnode.attrs.resource.getList({
								page: vnode.attrs.resource.list().page + 1,
								pageSize: vnode.attrs.resource.list().pageSize,
								selectedItemId: vnode.attrs.selectedItemId,
								sort: vnode.attrs.sort,
							})
						},
					},
					[m(icon, { iconName: 'angle-right' }), m('span.sr-only', 'Next')]
				),
			]
		),
	])
}

export default pagination
