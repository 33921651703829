﻿import m from 'mithril'
import Account from '../models/Account'

import notifications from '../components/notifications'

function routeCheck(isUnsavedChanges, unsavedState) {
	if (isUnsavedChanges()) {
		notifications.add({
			type: 'danger',
			timeToDisplay: 5000,
			title: 'Unsaved changes',
			message: 'You have unsaved changes! Please save or discard these changes to continue.',
		})

		// Set history back to previous page for window.location
		history.pushState(unsavedState().state, unsavedState().title, unsavedState().href)
		return new Promise(function () {})
	}

	return Account.user() && Account.user().id ? Promise.resolve(Account.user()) : Account.identity()
}

export default routeCheck
