﻿import m from 'mithril'
import prop from 'mithril/stream'
import { cloneDeep, isEqual } from 'lodash'

import Account from './Account'
import notifications from '../components/notifications'

var User = {}

User.list = prop()
User.getList = (data) => {
	m.request({
		method: 'GET',
		data: data,
		url: 'https://bifa-api.azurewebsites.net/voting/api/users',
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(User.list)
}

User.config = prop()
User.getConfig = () => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/config`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(User.config)
}

User.subgroups = prop()
User.getSubgroups = () => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/users/${Account.user().id}/subgroups`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(User.subgroups)
}

User.films = prop()
User.getUserFilms = () => {
	return m
		.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/my`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
		.then(User.films)
}

User.survey = prop()
User.getSurvey = (id) => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/surveys/${id}`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(User.survey)
}
User.surveyAnswers = prop()
User.getSurveyAnswers = (id) => {
	m.request({
		method: 'GET',
		url: `https://bifa-api.azurewebsites.net/voting/api/surveyanswers/${id}`,
		config: (xhr) => {
			xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
		},
	}).then(User.surveyAnswers)
}

User.current = prop()
User.original = prop()
User.clear = () => {
	User.current(false)
	User.original(false)
}
User.get = (id) => {
	User.current = prop()
	User.original = prop()

	return m
		.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/users/${id}`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((clean) => {
			User.current(clean)
			User.original(cloneDeep(clean))
		})
}

User.discard = () => {
	User.current(cloneDeep(User.original()))
}
User.hasChanges = () => {
	return !isEqual(User.current(), User.original())
}

User.save = () => {
	notifications.add({
		title: 'Saving account',
		type: 'saving',
	})
	return m
		.request({
			method: 'PUT',
			url: `https://bifa-api.azurewebsites.net/voting/api/users/${User.current().id}`,
			data: User.current(),
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((clean) => {
			User.current(clean)
			User.original(cloneDeep(clean))
			Account.identity()
			notifications.clear()
			notifications.add({
				title: 'Account saved',
				type: 'success',
			})
		})
}

User.completeTraining = () => {
	notifications.add({
		title: 'Saving confirmation',
		type: 'saving',
	})
	return m
		.request({
			method: 'POST',
			url: `https://bifa-api.azurewebsites.net/voting/api/users/${User.current().id}/ubtraining`,
			data: User.current(),
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			},
		})
		.then((clean) => {
			User.current(clean)
			User.original(cloneDeep(clean))
			Account.identity()
			notifications.clear()
			notifications.add({
				title: 'Account saved',
				type: 'success',
			})
			m.route.set('/')
		})
}

export default User
