﻿import m from "mithril"

function uploadHelper (files, uploadTarget, model, id) {
    const formData = new FormData;
    for (let i = 0; i < files.length; i++) {
        formData.append(`file${i}`, files[i])
    }
    return m.request({
        method: "POST",
        url: `/api/${uploadTarget}/${model ? model + "/" : ""}${id ? id + "/" : ""}upload`,
        data: formData,
        config: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("accessToken"));
        }
    })
}
export {uploadHelper}