﻿// Libraries
import m from 'mithril'
import { map, groupBy, keys, sortBy, filter, find } from 'lodash'
import tooltip from '../helpers/tooltip'

import Film from '../models/Film'
import User from '../models/User'
import Round from '../models/Round'
// Components
import { card } from 'bootstrap-mithril'
import loading from '../components/loading'
import icon from '../components/icon'
import table from '../components/table'
//import chartFilmsEntered from '../charts/chartFilmsEntered'

import variables from '../helpers/variables'

const dashboard = {}

dashboard.oninit = (vnode) => {
	User.getConfig()
	User.getSubgroups(vnode.attrs.user().id)
	User.getUserFilms()
}
dashboard.view = (vnode) => {
	return [
		m('.container-fluid', [
			m('.row.main-content', [
				m('.col-md-8', [
					m(card, {
						classes: ['mb-3'],
						header: `Welcome to BIFA ${variables.year} Viewing & Voting`,
						body: [
							User.config() &&
								User.config().votingDashboard &&
								m('p', m.trust(User.config().votingDashboard.replaceAll('\n', '<br>'))),
							vnode.attrs.user() && vnode.attrs.user().isMember
								? m('div', [
										//m('p', `Viewing and voting currently closed.`),
										m('p', `Get started by viewing the ${variables.year} films.`),
										m('p', `Get started by setting your preferences for this year's voting.`),
										// m('p', [
										// m(
										// 	'a.btn.btn-info.mr-1.mb-1',
										// 	{
										// 		href: `/me/voting`,
										// 		oncreate: m.route.link,
										// 	},
										// 	`${variables.year} Voting Preferences`
										// ),
										m('p', [
											m(
												'a.btn.btn-info.mr-1.mb-1',
												{
													href: `/viewing/all-shorts`,
													oncreate: m.route.link,
												},
												`${variables.year} Short Films`
											),
											m(
												'a.btn.btn-info.mr-1.mb-1',
												{
													href: `/viewing/all-features`,
													oncreate: m.route.link,
												},
												`${variables.year} Feature Films`
											),
										]),
								  ])
								: vnode.attrs.user() && !vnode.attrs.user().isMember
								? m('p', 'Please check your submitted films by clicking the links to view the entries.')
								: m(loading),
						],
						footer: [
							User.subgroups()
								? map(User.subgroups(), (sg) => {
										return m(
											'a.btn.btn-info.mr-1.mb-1',
											{
												href: `/viewing/${sg.id}`,
												oncreate: m.route.link,
											},
											`${sg.name} films`
										)
								  })
								: null,
							Round.list() &&
								Round.list().length > 0 &&
								vnode.attrs.user() &&
								vnode.attrs.user().isMember &&
								m(
									'a.btn.btn-info.mr-1.mb-1',
									{
										href: '/viewing/all',
										oncreate: m.route.link,
									},
									'View all films'
								),
						],
					}),
				]),
				m('.col-md-4', [
					Round.list() &&
						Round.list().length > 0 &&
						vnode.attrs.user() &&
						vnode.attrs.user().isMember &&
						m(card, {
							header: `Rounds`,
							classes: ['mb-3'],
							body: [
								map(Round.list(), (round) => {
									return m(
										'a.btn.btn-info.btn-block',
										{
											href: round.isViewingRound ? `/viewing/all` : `/rounds/${round.number}`,
											oncreate: m.route.link,
										},
										[m(icon, { iconName: 'tasks sb-icon' }), round.name]
									)
								}),
							],
						}),
					User.subgroups() && User.subgroups().length
						? m(card, {
								header: `Your Subgroup${User.subgroups().length > 1 ? 's' : ''}`,
								bodyClasses: ['p-0'],
								body: [
									m('p.p-3', `You have been assigned the following subgroups (click to view films):`),
									m('div.list-group.list-group-flush', [
										map(User.subgroups(), (subgroup) => {
											var sgFilms = Film.list()
												? filter(
														Film.list(),
														(film) => filter(film.entries, (cat) => cat.subgroupId === subgroup.id).length
												  )
												: null
											return m(
												'a.list-group-item.list-group-item-action',
												{ href: `/viewing/${subgroup.id}`, oncreate: m.route.link },
												[
													subgroup.name,
													m(
														'span.badge.badge-info.ml-2',
														{
															title: 'Films entered',
														},
														sgFilms ? sgFilms.length : null
													),
													sgFilms
														? m('.progress', [
																m(
																	'.progress-bar.bg-primary',
																	Object.assign({}, tooltip(), {
																		role: 'progressbar',
																		style: `width:${
																			(filter(sgFilms, (s) => {
																				return s.isSeen && !s.isConflicted
																			}).length /
																				sgFilms.length) *
																			100
																		}%`,
																		title: `${Math.floor(
																			(filter(sgFilms, (s) => {
																				return s.isSeen && !s.isConflicted
																			}).length /
																				sgFilms.length) *
																				100
																		)}% Seen`,
																	})
																),
																m(
																	'.progress-bar.bg-danger',
																	Object.assign({}, tooltip(), {
																		role: 'progressbar',
																		style: `width:${(filter(sgFilms, 'IsConflicted').length / sgFilms.length) * 100}%`,
																		title: `${Math.floor(
																			(filter(sgFilms, 'IsConflicted').length / sgFilms.length) * 100
																		)}% Conflicted`,
																	})
																),
																m(
																	'.progress-bar.bg-secondary',
																	Object.assign({}, tooltip(), {
																		role: 'progressbar',
																		style: `opacity:0.5;width:${
																			(filter(sgFilms, (s) => {
																				return !s.isSeen && !s.isConflicted
																			}).length /
																				sgFilms.length) *
																			100
																		}%`,
																		title: `${Math.floor(
																			(filter(sgFilms, (s) => {
																				return !s.isSeen && !s.isConflicted
																			}).length /
																				sgFilms.length) *
																				100
																		)}% Not Seen / Conflicted`,
																	})
																),
														  ])
														: null,
												]
											)
										}),
									]),
								],
						  })
						: null,
					User.films() &&
						User.films().length > 0 &&
						m(card, {
							header: `My Films`,
							classes: ['mb-3'],
							body: [
								map(
									find(User.films(), (x) => {
										return x.isLonglisted
									})
										? filter(User.films(), (x) => {
												return x.isLonglisted
										  })
										: User.films(),
									(film) => {
										return m(
											'a.btn.btn-info.btn-block.text-left',
											{
												href: `/entrant-films/${film.id}`,
												oncreate: m.route.link,
											},
											[m(icon, { iconName: 'film sb-icon' }), film.title]
										)
									}
								),
							],
						}),
				]),
			]),
		]),
	]
}

export default dashboard
